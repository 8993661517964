import { TextField, InputAdornment, Icon, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import styles from "./LoginPage1.module.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config, { node_api_endpoint } from "../../service/config";
import companyStore from "../Content/CompanyStore";

const Login = ({ className = "" }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [otp, setOTP] = useState("");
  const [otpSend, setOtpSend] = useState(false);
  console.log("in login");

  const auth = localStorage.getItem("authToken");
  console.log("authentication", auth);
  useEffect(() => {
    if (auth) {
      navigate("/content");
    }
  }, []);
  const handleLogin = () => {
    axios
      .post(`${config.node_api_endpoint}/v1/service/auth/generate_otp`, { email })
      .then(() => {
        setOtpSend(true);
        companyStore.setemail_id("devaudit@phedoratech.com");
        console.log(email);
      })
      .catch(() => {
        alert("invalid userName");
      });
  };
  const HandleOtp = () => {
    axios
      .post(`${config.node_api_endpoint}/v1/service/auth/verify_otp`, { email, otp })
      .then((res) => {
        navigate("content");
        console.log("response we got from the API", res);
        const authToken = res.data.token;
        localStorage.setItem("authToken", authToken);
        console.log(authToken);
      })
      .catch(() => {
        alert("Invalid OTP");
      });
  };
  return (
    <div className={[styles.pageContainer, className].join(" ")}>
      <div className={styles.card}>
        <div className={styles.logo}>
          <img className={styles.escrowstackLogos27Icon} alt="" src="/Layer1.svg" />
        </div>
        <div className={styles.welcomeToYourEscrowStackParent}>
          <div className={styles.welcomeToYour}>Welcome to your Escrow Stack</div>
          <div className={styles.pleaseSignInTo}>Please sign-in to continue</div>
        </div>
        {!otpSend ? (
          <div className={styles.inputoutlinedParent}>
            <TextField
              className="input-box"
              autoFocus
              fullWidth
              onChange={(e) => setEmail(e.target.value?.trim())}
              value={email}
              label="Enter your Email"
              sx={{
                mb: 5,
                borderRadius: "12px",
              }}
            />

            <div
              className={styles.loadsButtons}
              onClick={() => {
                handleLogin();
              }}
            >
              <img className={styles.boxArrowInDownIcon} alt="" src="/boxarrowindown3.svg" />
              <b className={styles.loads}>Get OTP</b>
            </div>
          </div>
        ) : (
          <div className={styles.inputoutlinedParent}>
            <TextField
              className="input-box"
              autoFocus
              fullWidth
              inputProps={{ maxLength: 6 }}
              onChange={(e) => setOTP(e.target.value?.trim())}
              value={otp}
              label="Enter OTP"
              sx={{
                mb: 5,
                borderRadius: "12px",
              }}
            />

            <div
              className={styles.loadsButtons}
              onClick={() => {
                HandleOtp();
              }}
            >
              <img className={styles.boxArrowInDownIcon} alt="" src="/boxarrowindown3.svg" />
              <b className={styles.loads}>VERIFY OTP</b>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

Login.propTypes = {
  className: PropTypes.string,
};

export default Login;
