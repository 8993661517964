import React, { useCallback, useEffect, useState } from "react";
import {
  TextField,
  Button,
  FormHelperText,
  MenuItem,
  Select,
  InputLabel,
  OutlinedInput,
  FormControl,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./NewEsign.module.css";
import config from "../../../../service/config";
import Lottie from "lottie-react";
import load from "./LoadGIF.json";
import axios from "axios";
import { DateTimePicker, DateTimePickerTabs } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import companyStore from "../../CompanyStore";

const NewEsign = ({ className = "" }) => {
  const [id, setId] = useState("");
  const [docUrl, setDocUrl] = useState("");
  const [partiesState, setPartiesState] = useState([{ name: "", email: "" }]);

  const navigate = useNavigate();
  // const [kycResponseData, setKycResponseData] = useState([]);
  const [pdf, setPdf] = useState("application/pdf");
  const [parties, setParties] = useState([]);
  const formdata = new FormData();
  const [agreementName, setAgreementName] = useState("");
  const [expiryDate, setExpiryDate] = useState(null);
  const [formattedDate, setformattedDate] = useState("");
  const [clicked, setClicked] = useState(false);
  const [errors, setErrors] = useState({});
  const onFrameContainerClick = useCallback(() => {
    navigate("/content/esign/esign/main");
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      const payload = {
        escrow_id: companyStore.escrowId,
      };

      try {
        const response = await axios.post(`${config.node_api_endpoint}/v2/company/list`, payload);
        const data = response.data;
        const director_ids = data[0]?.director_ids;
        let kycResponse = [];
        if (director_ids) {
          kycResponse = await axios.post(`${config.node_api_endpoint}/v2/company/kycparties`, { director_ids });
        } // setKycResponseData(kycResponse.data);
        let filteredData = kycResponse?.data?.filter(
          (data) => data.status === "approved" || data.status === "SUBMITTED",
        );

        setParties(
          filteredData?.map((detail) => {
            return {
              name: detail.director_name,
              email: detail.director_email,
            };
          }),
        );
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [companyStore]);

  useEffect(() => {
    if (companyStore.Contract_id !== "") {
      let obj = {
        escrow_id: companyStore.escrowId,
        contract_id: companyStore.Contract_id,
      };
      axios
        .post(`${config.mock_api_endpoint}/api/getContractData`, obj)
        .then((res) => {
          setAgreementName(res.data.contract_name);
          companyStore.setContractName(res.data.contract_name);
          setDocUrl(res.data.filePath);
        })
        .catch((err) => {
          console.log("%o", err);
        });
    }
  }, []);

  const handleAddParty = () => {
    setPartiesState((prevParties) => [...prevParties, { name: "", email: "" }]);
  };

  const handleRemoveParty = (index) => {
    const updatedParties = partiesState.filter((_, i) => i !== index);
    setPartiesState(updatedParties);
  };

  const handleClearAll = () => {
    setAgreementName("");
    setExpiryDate(null);
    setParties([{ name: "", email: "" }]);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type !== "application/pdf") {
      alert("Please upload only PDF files.");
      e.target.value = "";
      return;
    }

    setPdf(file);
  };
  const formatnewDate = (dateString) => {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const validateForm = () => {
    const newErrors = {};

    // Validate agreement name
    if (!agreementName) {
      newErrors.agreementName = "Agreement name is required.";
    }

    // Validate parties
    if (partiesState.length === 0) {
      newErrors.parties = "At least one party is required.";
    } else {
      partiesState.forEach((party, index) => {
        if (!party.name.trim()) {
          newErrors[`partyName${index}`] = `Name is required for party ${index + 1}.`;
        }
        if (!party.email.trim()) {
          newErrors[`partyEmail${index}`] = `Email is required for party ${index + 1}.`;
        } else if (!validateEmail(party.email)) {
          newErrors[`partyEmail${index}`] = `Invalid email format for party ${index + 1}.`;
        }
      });
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleContinue = () => {
    // const date = `${expiryDate}`;
    // const FinalDate = formatnewDate(date);

    const filteredParties = partiesState.filter((party) => party.name.trim() !== "" && party.email.trim() !== "");
    if (docUrl?.length > 0) {
      // Validate form and display errors if any
      if (!validateForm()) {
        alert("Please fix the highlighted errors.");
        return;
      }
      // Format date for submission

      setClicked(true);
      formdata.append("agreement_name", agreementName);
      formdata.append("escrow_id", companyStore.escrowId);
      // formdata.append("expiry_date", FinalDate);
      formdata.append("signing_parties", JSON.stringify(filteredParties));
      formdata.append("pdf_url", docUrl);
      // formdata.append("pdf_file", pdf, "[PROXY]");

      axios
        .post(`${config.node_api_endpoint}/v2/esign/upload`, formdata)
        .then((res) => {
          companyStore.setDoc_id(res.data._id);
          navigate(`/content/esign/signaggr`);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("pdf-->", pdf);
      if (!pdf.name) {
        alert("Please upload the document.");
        return;
      }
      if (!validateForm()) {
        alert("Please fix the highlighted errors.");
        return;
      }

      setClicked(true);
      formdata.append("agreement_name", agreementName);
      formdata.append("escrow_id", companyStore.escrowId);
      // formdata.append("expiry_date", FinalDate);
      formdata.append("signing_parties", JSON.stringify(filteredParties));
      formdata.append("pdf_file", pdf, "[PROXY]");
      axios
        .post(`${config.node_api_endpoint}/v2/esign/manualupload`, formdata)
        .then((res) => {
          companyStore.setDoc_id(res.data._id);
          navigate(`/content/esign/signaggr`);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // Email validation function
  const validateEmail = (email) => {
    const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    return regex.test(email);
  };

  const handleNameChange = (e, index) => {
    const selectedName = e.target.value;
    const selectedParty = parties.find((p) => p.name === selectedName);

    const newPartiesState = [...partiesState];
    newPartiesState[index].name = selectedName;
    newPartiesState[index].email = selectedParty ? selectedParty.email : "";
    setPartiesState(newPartiesState);
  };

  const handleEmailChange = (e, index) => {
    const newPartiesState = [...partiesState];
    newPartiesState[index].email = e.target.value;
    setPartiesState(newPartiesState);
  };

  return (
    <div className={[styles.frameParent, className].join(" ")}>
      <div className={styles.frameWrapper} onClick={onFrameContainerClick}>
        <div className={styles.arrowNarrowLeftParent}>
          <img className={styles.arrowNarrowLeftIcon} alt="" src="/arrownarrowleft.svg" />
          <div className={styles.back}>Back</div>
        </div>
      </div>
      <div></div>

      <div className={styles.frameGroup}>
        {/*  */}
        {docUrl?.length > 0 ? (
          <div className={styles.frameContainer}>
            <div className={styles.uploadTheFinalDraftOfAgreParent}>
              <div className={styles.uploadTheFinal}>+ Upload the final draft of agreement</div>
              <div className={styles.uploadTheFinal}>Add parties (by default show 2 Inputs)</div>
            </div>
            <iframe src={docUrl} type="application/pdf" width="100%" height="500px" />
          </div>
        ) : (
          <div className={styles.frameContainer}>
            <div className={styles.uploadTheFinalDraftOfAgreParent}>
              <div className={styles.uploadTheFinal}>+ Upload the final draft of agreement</div>
              <div className={styles.uploadTheFinal}>Add parties (by default show 2 Inputs)</div>
            </div>
            <img className={styles.imagePlaceholderIcon} alt="" src="/image-placeholder.svg" />
            <button className={styles.groupParent}>
              <img className={styles.frameChild} alt="" src="/group-6.svg" />
              <div className={styles.dragDropContainer}>
                <span className={styles.dragDrop}>{`Drag & drop the file here or   `}</span>
                <input type="file" className={styles.fileInput} accept="application/pdf" onChange={handleFileChange} />
              </div>
            </button>
          </div>
        )}
        <div className={styles.frameDiv}>
          <div className={styles.partiesParent}>
            <div className={styles.parties}>Parties</div>
            {/* <div className={styles.input} onClick={handleClearAll}>
              Clear all
            </div> */}
          </div>
          <ol>
            <div className="d-flex justify-content-start align-items-center">
              <div className="pe-4 w-100">
                <TextField
                  className="w-100"
                  color="primary"
                  label="Name of Agreement"
                  variant="outlined"
                  value={agreementName}
                  onChange={(e) => setAgreementName(e.target.value)}
                  sx={{
                    "& .MuiInputBase-root": { height: "56px" },
                  }}
                  error={!!errors.agreementName}
                  helperText={errors.agreementName || ""}
                />
              </div>
            </div>

            {partiesState?.map((party, index) => {
              const selectedNames = partiesState.map((p) => p.name).filter((_, i) => i !== index);
              return (
                <div key={index} className={styles.frameWrapper1}>
                  <li className={styles.partieslistitem}>Parties</li>
                  <div className={styles.mobileMenuParent}>
                    <div className={styles.mobileMenu2}>
                      <FormControl sx={{ m: 1, width: 300 }} error={!!errors[`partyName${index}`]}>
                        <InputLabel id="demo-multiple-name-label">Name</InputLabel>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          value={party.name || ""}
                          onChange={(e) => {
                            handleNameChange(e, index);
                          }}
                          error={!!errors[`partyName${index}`]}
                          helperText={errors[`partyName${index}`] || ""}
                          sx={{
                            "& .MuiInputBase-root": { height: "56px" },
                            width: "309px",
                          }}
                          input={<OutlinedInput label="Name" />}
                        >
                          {parties
                            ?.filter((p) => !selectedNames.includes(p.name))
                            .map((filteredParty, idx) => (
                              <MenuItem key={idx} value={filteredParty.name}>
                                {filteredParty.name}
                              </MenuItem>
                            ))}
                        </Select>
                        {errors[`partyName${index}`] && (
                          <FormHelperText error>{errors[`partyName${index}`]}</FormHelperText>
                        )}
                      </FormControl>
                      <img className={styles.trailingIcon2} alt="" src="/trailingicon-2.svg" />
                    </div>
                    <FormControl sx={{ m: 1, width: 300 }}>
                      <TextField
                        className={styles.mobileMenu3}
                        color="primary"
                        placeholder="Email ID"
                        variant="outlined"
                        type="email"
                        value={party.email || ""}
                        disabled={true}
                        // error={!!errors[`partyEmail${index}`]}
                        // helperText={errors[`partyEmail${index}`] || ""}
                        sx={{
                          "& .MuiInputBase-root": { height: "56px" },
                          width: "309px",
                          "& .MuiInputBase-input.Mui-disabled": {
                            opacity: 1,
                            "-webkit-text-fill-color": "rgba(0, 0, 0, 0.38)",
                            cursor: "not-allowed",
                          },
                        }}
                      />
                    </FormControl>
                  </div>
                  {partiesState.length > 1 && (
                    <Button className={styles.inputParent} onClick={() => handleRemoveParty(index)}>
                      <div className={styles.input1}>Remove</div>
                      <img className={styles.arrowNarrowLeftIcon} alt="" src="/minuscircle.svg" />
                    </Button>
                  )}
                </div>
              );
            })}
          </ol>

          <div className={styles.containbuttons}>
            {partiesState?.length < parties?.length && (
              <Button className={styles.addDirector} onClick={handleAddParty}>
                <img className={styles.boxArrowInDownIcon} alt="" src="/boxarrowindown1.svg" />
                <img className={styles.arrowNarrowLeftIcon} alt="" src="/pluscircle1.svg" />
                <b className={styles.addDirector1}>Add Parties</b>
              </Button>
            )}
            <div
              className={styles.generateRequest}
              onClick={() => {
                handleContinue();
              }}
            >
              {clicked && <Lottie className={styles.httpslottiefilescomanimatIcon} animationData={load} loop={true} />}
              <b className={styles.generateRequest1}>Continue</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

NewEsign.propTypes = {
  className: PropTypes.string,
};

export default NewEsign;
