import { useEffect, useState } from "react";
import InspectPdf from "../InspectPdf/Inspectpdf";
import styles from "./DocumentPreview.module.css";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import config from "../../../../service/config";
import companyStore from "../../CompanyStore";

const DocumentPreview = () => {
  const navigate = useNavigate();
  const [comp, setComp] = useState("");
  const [info, setInfo] = useState({});
  const [data, setData] = useState([]);
  const [id, setId] = useState("");

  const [doc_id, setDoc_id] = useState("");
  const [clicked, setclicked] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  useEffect(() => {
    setId(companyStore.companyID);
    setDoc_id(companyStore.doc_id);
    console.log("docid", doc_id);
    const iddd = companyStore.doc_id;
    let obj = { docid: companyStore.doc_id };
    axios
      .post(`${config.node_api_endpoint}/v1/esign/getdocument/`, { doc_id: companyStore.doc_id })
      .then((res) => {
        console.log("Document preview-->", res.data);
        setData(res.data.signing_parties);
        setInfo(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log("%o", err);
      });
  }, []);

  const handleDocumentDonload = (newlink) => {
    window.open(newlink, "_blank").focus();
  };
  const handlenavi = () => {
    navigate(`/content/esign/esign/main`);
  };

  return (
    <div className={styles.maindiv}>
      <div
        className={styles.arrowNarrowLeftParent}
        onClick={() => {
          handlenavi();
        }}
      >
        <img className={styles.arrowNarrowLeftIcon} alt="" src="/arrownarrowleft.svg" />
        <div className={styles.back}>Back</div>
      </div>

      <div className={styles.containAll}>
        <div className={styles.scrollableContainer}>
          <InspectPdf pdfUrl={pdfUrl} setPdfUrl={setPdfUrl} />
        </div>
        <div className={styles.detailsContentWrapper}>
          <div className={styles.detailsContent}>
            <div className={styles.companyAddress}>
              <h1 className={styles.escrowStackPrivate}>{/* {} */}</h1>
            </div>
            <div className={styles.frameContainer}>
              <div className={styles.requestedByLabelParent}>
                <div className={styles.requestedByLabel}>
                  <div className={styles.requestedBy}>Requested by</div>
                </div>
                <div className={styles.div}>:</div>
              </div>
              <div className={styles.digitalTechnologiesPrivate}>{info.company_name || "pavan@aubank.in"}</div>
            </div>
            {/* <div className={styles.detailsHeaders}>
              <div className={styles.detailsItems}>
                <div className={styles.detailValues}>
                  <div className={styles.expiry}>Expiry</div>
                </div>
                <div className={styles.emptyCells}>:</div>
              </div>
              {console.log("date", info?.expiry_date)}
              <div className={styles.signatureTypes}>{info?.expiry_date || ""}</div>
            </div> */}
            <div className={styles.detailsHeaders1}>
              <div className={styles.frameDiv}>
                <div className={styles.requestedWrapper}>
                  <div className={styles.requested}>Requested</div>
                </div>
                <div className={styles.emptyCells}>:</div>
              </div>
              <div className={styles.div2}>{info?.created_at?.split("T")[0] || ""}</div>
            </div>
            <div className={styles.signDetailsWrapper}>
              <div className={styles.signDetails}>Sign Details</div>
            </div>
            <div></div>
            {data.map((detail, index) => (
              <div key={index}>
                <div className={styles.detailsHeaders2}>
                  <div className={styles.frameParent1}>
                    <div className={styles.nareshKumarWrapper}>
                      <div className={styles.nareshKumar}>{detail.name}</div>
                    </div>
                    <div className={styles.div3}>:</div>
                  </div>
                  <div className={styles.signedWithAadhaar}>{detail?.status?.toUpperCase() || ""}</div>
                </div>
              </div>
            ))}
            {/* <div className={styles.detailsHeaders2}>
                  <div className={styles.frameParent1}>
                    <div className={styles.nareshKumarWrapper}>
                      <div className={styles.nareshKumar}>Naresh Kumar</div>
                    </div>
                    <div className={styles.div3}>:</div>
                  </div>
                  <div className={styles.signedWithAadhaar}>
                    (Signed with Aadhaar)
                  </div>
                </div>
                <div className={styles.detailsHeaders3}>
                  <div className={styles.frameParent2}>
                    <div className={styles.shivakumarWrapper}>
                      <div className={styles.shivakumar}>ShivaKumar</div>
                    </div>
                    <div className={styles.div4}>:</div>
                  </div>
                  <div className={styles.signedWithAadhaar1}>
                    (Signed with Aadhaar)
                  </div>
                </div> */}
            {/* <DownloadPDF /> */}

            <button
              src={info.pdf_url}
              onClick={() => {
                // handleDocumentDonload(info?.pdf_url || "");
                handleDocumentDonload(pdfUrl || "");
              }}
              className={styles.downloadbtn}
            >
              <b>Download PDF</b>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentPreview;
