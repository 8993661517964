import { Select, InputLabel, MenuItem, FormHelperText, FormControl } from "@mui/material";
import PropTypes from "prop-types";
import styles from "./MakeEditor.module.css";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../../../service/config";
import companyStore from "../../../CompanyStore";

const MakeEditor = ({ isVisible, onclose, className = "" }) => {
  const [users, setUsers] = useState([]);
  const [selectedActions, setSelectedActions] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currenteditor, setcurrenteditor] = useState("");
  const [data, setData] = useState({});
  const [doc_link, setDocLink] = useState("");
  const formData = new FormData();

  useEffect(() => {
    setLoading(true);
    const obj = {
      escrow_id: companyStore?.escrowId,
      contract_id: companyStore?.Contract_id,
    };

    axios
      .post(`${config.mock_api_endpoint}/api/getContractData`, obj)
      .then((res) => {
        setData(res.data);
        let accessNotRemoved = res.data.counter_parties.filter((data) => data.status !== "Removed");
        console.log("accessNotRemoved-->", accessNotRemoved);

        setUsers(accessNotRemoved);
        const initialActions = {};
        res.data.counter_parties.forEach((user) => {
          initialActions[user._id] = "view"; // Use _id here
        });
        setSelectedActions(initialActions);
      })
      .catch((err) => {
        console.error("%o", err);
        setError("Failed to load data");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [isVisible, companyStore]);

  const handleActionChange = (userId, access) => {
    setSelectedActions((prev) => {
      const newActions = { ...prev, [userId]: access };
      console.log("newActions", newActions);

      if (access === "edit") {
        Object.keys(newActions).forEach((id) => {
          if (id !== userId) {
            newActions[id] = "view";
          } else {
            console.log("preveu", prev);

            setcurrenteditor();
          }
        });
      }
      return newActions;
    });
  };

  const handleSubmit = () => {
    var currentuser = "";
    const updatedActions = users.map((user) => {
      return {
        email: user.email, // Use _id for consistency
        access: selectedActions[user._id] || user.access,
      };
    });
    console.log("email-->", selectedActions);

    updatedActions.map((user) => {
      console.log(user.email);
      console.log(user.access);
      if (user.access == "edit") {
        console.log("user-->", user);

        setcurrenteditor(user.email);
        currentuser = user.email;
        console.log("currentuser", currentuser);

        setDocLink(user.doc_link);
      }
    });
    const payload = {
      escrow_id: companyStore.escrowId,
      contract_id: companyStore.Contract_id,
      current_edit_access: currentuser, // Send all updated actions
    };
    console.log("editor", payload);

    axios
      .post(`${config.mock_api_endpoint}/api/modifyAccess`, payload)
      .then((res) => {
        console.log("Access updated successfully", res.data);
        onclose(); // Close only after successful submission
        res.data.contract.counter_parties.forEach((rres) => {
          if (rres.email == currentuser) {
            console.log("user->>", rres);
            let body = `
            <html>
            <head>
                <style>
                    body { font-family: Arial, sans-serif; line-height: 1.6; color: #333; }
                    .container { max-width: 600px; margin: auto; padding: 20px; }
                    h1 { color: #007BFF; }
                    a { color: #007BFF; text-decoration: none; }
                    .button {
                        display: inline-block;
                        padding: 10px 20px;
                        font-size: 16px;
                        color: white;
                        background-color: #007BFF;
                        border: none;
                        border-radius: 5px;
                        text-decoration: none;
                        text-align: center;
                    }
                    .footer { margin-top: 20px; font-size: 0.9em; color: #777; }
                </style>
            </head>
          <body style="margin: 0; padding: 0">
      <div style="font-size: 0px; line-height: 1px; mso-line-height-rule: exactly; display: none; max-width: 0px; max-height: 0px; opacity: 0; overflow: hidden; mso-hide: all"></div>
      <center lang="und" dir="auto" style="width: 100%; table-layout: fixed; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%">
        <table class="bg-fffffe" cellpadding="0" cellspacing="0" border="0" role="presentation" bgcolor="white" width="657.00" style="border-radius: 12px; background-color: white; padding-top: 32px; padding-bottom: 32px; width: 657px; border-spacing: 0; font-family: Sarabun, Tahoma, sans-serif; min-width: 657px; border-collapse: separate">
          <tr>
            <td valign="middle" width="100.00%" style="width: 100%; vertical-align: middle">
              <table cellpadding="0" cellspacing="0" border="0" role="presentation" width="100.00%" style="width: 100%; border-spacing: 0">
                <tr>
                  <td align="center" style="padding-bottom: 13.76px">
                    <img src="assets/image_1885faaf.png" width="101" style="max-width: initial; width: 101px; display: block" />
                  </td>
                </tr>
                <tr>
                  <td align="left" style="padding-top: 13.76px; padding-bottom: 13.72px; padding-left: 32px">
                    <p class="color-4c4e64" width="100.00%" style="font-size: 20px; font-weight: 400; letter-spacing: 0.4px; color: #4C4E64; margin: 0; padding: 0; width: 100%; line-height: 21.44px; mso-line-height-rule: exactly"><span>Dear </span><span style="font-size: 20px; font-weight: 600; letter-spacing: 0.4px; text-align: left">${rres.name},</span></p>
                  </td>
                </tr>
                <tr>
                  <td align="left" style="padding-top: 13.72px; padding-bottom: 15.16px; padding-left: 32px; padding-right: 32px">
                    <p width="100.00%" style="text-align: left; color: #777777; margin: 0; padding: 0; width: 100%"><span class="color-777777" style="font-size: 20px; font-weight: 400; color: #777777; letter-spacing: 0.4px; text-align: left">You have been added as a </span><span style="font-size: 20px; font-weight: 600; color: #333333; letter-spacing: 0.4px; text-align: left">Editor</span><span class="color-777777" style="font-size: 20px; font-weight: 400; color: #777777; letter-spacing: 0.4px; text-align: left"> for the contract titled</span><span class="color-777777" style="font-size: 20px; font-weight: 600; color: #777777; letter-spacing: 0.4px; text-align: left"> </span><span style="font-size: 20px; font-weight: 600; color: #333333; letter-spacing: 0.4px; text-align: left">&quot;${data.contract_name} &quot;.</span><span class="color-777777" style="font-size: 20px; font-weight: 600; color: #777777; letter-spacing: 0.4px; text-align: left"> </span><span class="color-777777" style="font-size: 20px; font-weight: 500; color: #777777; letter-spacing: 0.4px; text-align: left"> As an editor, you can review and make changes to the document directly through our document editor. </span></p>
                  </td>
                </tr>
                <tr>
                  <td align="left" style="padding-top: 15.16px; padding-bottom: 7.58px; padding-left: 32px">
                    <p width="100.00%" style="font-size: 20px; font-weight: 700; letter-spacing: 0.4px; color: #333333; margin: 0; padding: 0; width: 100%; line-height: 15.68px; mso-line-height-rule: exactly">Please follow the link below to view the contract:</p>
                  </td>
                </tr>
                <tr>
                  <td style="padding-top: 7.58px; padding-left: 33px; padding-right: 33px">
                    <table class="bg-2348ab" cellpadding="0" cellspacing="0" border="0" role="presentation" bgcolor="#2348AB" width="591.00" height="40.00" style="border-radius: 30px; background-color: #2348AB; width: 591px; height: 40px; border-spacing: 0; border-collapse: separate">
                      <tr>
                        <td valign="middle" width="100.00%" height="40.00" style="width: 100%; vertical-align: middle; height: 40px">
    <a href="${config.doc_link_api_endpoint}${rres.doc_link}" style="text-decoration: none;">
      <p class="color-fffffe" width="100.00%" style="font-size: 16px; font-weight: 700; letter-spacing: 0.15px; color: white; margin: 0; padding: 0; width: 100%; line-height: 24px; text-align: center; mso-line-height-alt: 24px">
        Review Document
      </p>
    </a>
  </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </center>
    </body>
            </html>
        `;

            // console.log("payload mail-->", obj66);

            const emailPayload = {
              to: currentuser,
              body: body,
              subject: "You have been added as an Editor",
            };
            // formData.append("subject", "Request Editor");
            axios
              .post(`${config.email_api_endpoint}/send-email`, emailPayload, {
                headers: { "Content-Type": "multipart/form-data", "x-api-key": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9" },
              })
              .then((res) => {
                console.log(res);
              })
              .catch((err) => {
                console.log(err);
              });
          }
        });
      })
      .catch((err) => {
        console.error("Error updating access", err);
        setError("Failed to update access");
      });

    // console.log("Updated Actions:", updatedActions);
  };

  if (!isVisible) return null;

  return (
    <div className={[styles.makeEditor, className].join(" ")}>
      <div className={styles.makeEditorInner}>
        <div className={styles.frameParent}>
          <div className={styles.makeEditorParent}>
            <div className={styles.makeEditor1}>Make edit</div>
            <img className={styles.xCircleIcon} alt="" src="/xcircle.svg" onClick={onclose} />
          </div>

          <div className={styles.frameGroup}>
            <div className={styles.nameParent}>
              <b className={styles.name}>Name</b>
              <b className={styles.email}>Email</b>
              <b className={styles.name}>Access</b>
            </div>
            {loading ? (
              <div>Loading...</div> /* : error ? (
              <div className={styles.error}>{error}</div>
            )  */
            ) : users.length > 0 ? (
              users.map((user) => (
                <div className={`${styles.prasanthParent}`} key={user._id}>
                  <div className={styles.name}>{user.name}</div>
                  <div className={styles.email}>{user.email}</div>
                  <div className={styles.actionContainer}>
                    {user.access === "Owner" ? (
                      <div className={styles.cancelWrapper}>
                        <div className={styles.cancel}>
                          <img className={styles.boxArrowInDownIcon} alt="" src="/boxarrowindown1.svg" />
                          <b className={styles.name}>Owner</b>
                        </div>
                      </div>
                    ) : (
                      <FormControl
                        className={styles.editorParent}
                        variant="outlined"
                        sx={{
                          borderRadius: "0px",
                          width: "135px",
                          height: "40px",
                          m: 0,
                          p: 0,
                        }}
                      >
                        <InputLabel color="primary">Access</InputLabel>
                        <Select
                          color="primary"
                          // value={selectedActions[user._id] || user.access}
                          onChange={(e) => handleActionChange(user._id, e.target.value)}
                          displayEmpty
                        >
                          <MenuItem value="edit">EDITOR</MenuItem>
                          <MenuItem value="view">VIEWER</MenuItem>
                        </Select>
                        <FormHelperText />
                      </FormControl>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div>No users found.</div>
            )}
          </div>
        </div>
        <div className={styles.button}>
          <button className={styles.btn} onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

MakeEditor.propTypes = {
  className: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  onclose: PropTypes.func.isRequired,
};

export default MakeEditor;
