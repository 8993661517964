import { FormControl, TextField, CircularProgress, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { IconButton, LinearProgress, Typography, Box, Button, Paper } from "@mui/material";
import { CloudUpload, Close, Check } from "@mui/icons-material";
import axios from "axios";
import config from "../../../../../service/config";
import companyStore from "../../../CompanyStore";
import { useNavigate } from "react-router-dom";

const OfflineKyc = () => {
  const [cin, setCin] = useState("");
  const [pan, setPan] = useState("");
  const [gst, setGst] = useState("");
  const [remark, setRemark] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [files, setFiles] = useState([]);
  const [escrow_id, setEscrow_Id] = useState([]);
  const [company_id, setCompany_Id] = useState([]);
  const [admin, setAdmin] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();
  const [fieldErrors, setFieldErrors] = useState({});

  useEffect(() => {
    setEscrow_Id(companyStore.escrowId);
    setCompany_Id(companyStore.companyID);
    setAdmin(companyStore.AdminEmail);
  }, []);
  console.log("escrow_id", escrow_id);

  const validateField = (field, value) => {
    let errorMessage = "";

    switch (field) {
      case "cin":
        if (!value) errorMessage = "CIN is required.";
        else if (!/^[A-Za-z0-9]{21}$/i.test(value)) errorMessage = "CIN must be 21 alphanumeric characters.";
        break;

      case "pan":
        if (!value) errorMessage = "PAN is required.";
        else if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/i.test(value)) errorMessage = "PAN must be a valid format (ABCDE1234F).";
        break;

      case "gst":
        if (!value) errorMessage = "GSTIN is required.";
        else if (!/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{3}$/i.test(value))
          errorMessage = "GSTIN must be in a valid format.";
        break;

      // case "files":
      //   if (files.length === 0) errorMessage = "At least one file must be uploaded.";
      //   break;

      default:
        break;
    }

    return errorMessage;
  };

  const validateForm = () => {
    const errors = {
      cin: validateField("cin", cin),
      pan: validateField("pan", pan),
      gst: validateField("gst", gst),
      // files: validateField("files", files),
    };

    setFieldErrors(errors);

    return !Object.values(errors).some((error) => error);
  };

  const handleClear = () => {
    setCin("");
    setPan("");
    setGst("");
    setRemark("");
    setFiles([]);
    setError("");
    setFieldErrors("");
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setLoading(true);
    try {
      if (files.length === 0) alert("please upload document");
      else {
        const formData = new FormData();
        formData.append("escrow_id", escrow_id);
        formData.append("company_id", company_id);
        formData.append("submitted_by", admin);
        formData.append("cin", cin);
        formData.append("pan", pan);
        formData.append("gst", gst);
        formData.append("mode", "offline");
        formData.append("remark", remark);

        files.forEach((file) => {
          // console.log("fileData-->",file);

          formData.append("files", file.file);
        });

        await axios.post(`${config.node_api_endpoint}/v2/company/manualkyc`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setError("Submission successful!");
        navigate(`/content/company/kycsuccess`);
      }
    } catch (error) {
      setError("An error occurred while submitting. Please try again.");
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  const handleFileChange = (event) => {
    console.log("files", event.target.files);

    const newFiles = Array.from(event.target.files).map((file) => ({
      file,
      name: file.name,
      size: (file.size / (1024 * 1024)).toFixed(2) + " MB",
      progress: 0,
    }));

    const totalSize = newFiles.reduce((acc, file) => acc + file.file.size, 0) / (1024 * 1024);
    if (files.length + newFiles.length > 10 || totalSize > 5) {
      setError("Max 10 files and total file size 5MB");
      setSnackbarOpen(true);
      return;
    }

    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    simulateUpload(newFiles);
  };

  const simulateUpload = (newFiles) => {
    newFiles.forEach((file) => {
      const interval = setInterval(() => {
        setFiles((prevFiles) => {
          const updatedFiles = prevFiles.map((f) => {
            if (f.name === file.name) {
              const newProgress = Math.min(f.progress + 10, 100);
              if (newProgress === 100) {
                clearInterval(interval);
              }
              return { ...f, progress: newProgress };
            }
            return f;
          });
          return updatedFiles;
        });
      }, 200);
    });
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleVerify = async () => {
    if (!validateForm()) return;
    setLoading(true);
    const formData = new FormData();
    formData.append("company_id", company_id);
    formData.append("cin", cin);
    formData.append("pan", pan);
    formData.append("gst", gst);
    formData.append("mode", "offline");
    formData.append("submitted_by", admin);
    formData.append("remark", remark);
    files.forEach((file) => {
      formData.append("files", file.file);
    });
    try {
      const res1 = await axios.post(
        `${config.webhook_api_endpoint}/v2/company/verifykyc`,
        // `http://192.168.0.95:5051/v2/company/verifykyc`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      console.log("res111-->>", res1);
      setError("Submission successful!");
      navigate(`/content/company/kycsuccess`);
      // if (res1.status === 200) {
      //   console.log(res1.status);

      //   setLoading(false);
      //   setSuccessPage(true);
      //   navigate(`/content/company/kycsuccess`);
      // }
    } catch (err) {
      setError("Error occurred while submitting the form");
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="title">Company KYC</div>
          <div
            style={{ cursor: "pointer", fontSize: "16px", textDecoration: "none", color: "#4C4E64" }}
            onClick={handleClear}
          >
            Clear
          </div>
        </div>

        <div className="p-4">
          <FormControl sx={{ width: "500px", pr: 2 }}>
            <TextField
              label="CIN"
              value={cin}
              onChange={(e) => setCin(e.target.value)}
              variant="outlined"
              onBlur={() => setFieldErrors((prev) => ({ ...prev, cin: validateField("cin", cin) }))}
              error={!!fieldErrors.cin}
              helperText={fieldErrors.cin}
            />
          </FormControl>
        </div>
        <div className="p-4">
          <FormControl sx={{ width: "500px", pr: 2 }}>
            <TextField
              label="PAN"
              value={pan}
              onChange={(e) => setPan(e.target.value)}
              variant="outlined"
              onBlur={() => setFieldErrors((prev) => ({ ...prev, pan: validateField("pan", pan) }))}
              error={!!fieldErrors.pan}
              helperText={fieldErrors.pan}
            />
          </FormControl>
        </div>
        <div className="p-4">
          <FormControl sx={{ width: "500px", pr: 2 }}>
            <TextField
              label="GSTIN"
              value={gst}
              onChange={(e) => setGst(e.target.value)}
              variant="outlined"
              onBlur={() => setFieldErrors((prev) => ({ ...prev, gst: validateField("gst", gst) }))}
              error={!!fieldErrors.gst}
              helperText={fieldErrors.gst}
            />
          </FormControl>
        </div>
        <div className="p-4">
          <FormControl sx={{ width: "500px", pr: 2 }}>
            <TextField label="Remark" value={remark} onChange={(e) => setRemark(e.target.value)} variant="outlined" />
          </FormControl>
        </div>

        <Typography variant="h6" gutterBottom>
          Upload Documents
        </Typography>
        <Box sx={{ width: "100%", maxWidth: 400, margin: "auto", padding: 2 }}>
          {files.map((file, index) => (
            <Paper
              key={index}
              variant="outlined"
              sx={{
                display: "flex",
                alignItems: "center",
                padding: 1,
                marginBottom: 1,
                borderRadius: 2,
                border: "1px solid #e0e0e0",
              }}
            >
              <Box sx={{ flex: 1, display: "flex", flexDirection: "column", marginRight: 1 }}>
                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                  {file.name}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {file.size}
                </Typography>
              </Box>
              <Box sx={{ flex: 2, marginRight: 1 }}>
                {file.progress < 100 ? (
                  <LinearProgress variant="determinate" value={file.progress} sx={{ height: 8, borderRadius: 5 }} />
                ) : (
                  <Typography variant="body2" color="success.main">
                    Uploaded <Check fontSize="small" sx={{ marginLeft: 1 }} />
                  </Typography>
                )}
              </Box>
              <IconButton onClick={() => handleRemoveFile(index)} size="small">
                <Close fontSize="small" />
              </IconButton>
            </Paper>
          ))}
          <Button
            variant="contained"
            component="label"
            startIcon={<CloudUpload />}
            sx={{
              marginTop: 2,
              padding: 1.5,
              borderRadius: 2,
              width: "100%",
              backgroundColor: "#f5f5f5",
              color: "#1976d2",
              "&:hover": {
                backgroundColor: "#e0e0e0",
              },
            }}
          >
            Drag & drop the file here or Click to upload
            <input type="file" hidden multiple accept="application/pdf,image/jpeg" onChange={handleFileChange} />
          </Button>
          <Typography variant="caption" display="block" sx={{ marginTop: 1, textAlign: "center", color: "#757575" }}>
            (File type PDF & JPEG / Max 10 / Max Total File Size 5MB)
          </Typography>
        </Box>

        <div className="d-flex justify-content-around align-items-center p-4">
          <button
            type="button"
            style={{ backgroundColor: "#2348AB", color: "#FFFFFF" }}
            className="btn btn-primary mt-3 mb-2 w-50 me-2"
            disabled={loading}
            onClick={handleSubmit}
          >
            {loading ? <CircularProgress size={24} /> : "Submit"}
          </button>
          <button
            type="button"
            style={{ backgroundColor: "#FFFFFF", color: "#2348AB" }}
            className="btn btn-primary mt-3 mb-2 w-50 "
            disabled={loading}
            onClick={handleVerify}
          >
            {loading ? <CircularProgress size={24} /> : "Verify"}
          </button>
        </div>

        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} message={error} />
      </div>
    </div>
  );
};
export default OfflineKyc;
