import React, { useState, useEffect, useRef } from "react";
import Draggable from "react-draggable";
import axios from "axios";
import { useParams } from "react-router-dom";
import styles from "./InspectPdf.module.css";
import config from "../../../../service/config";
import companyStore from "../../CompanyStore";

const InspectPdf = ({ buttonClicked, pdfUrl, setPdfUrl }) => {
  const [items, setItems] = useState([]);
  const [imageData, setImageData] = useState({});
  const [draggables, setDraggables] = useState([]);
  const [id, setId] = useState("");
  const [doc_id, setDoc_id] = useState("");
  const containerRefs = useRef({});
  const [imageSizes, setImageSizes] = useState({}); // Store image dimensions
  const [data, setData] = useState();
  const [workFlowId, setWorkFlowId] = useState("");
  const [authToken, setAuthToken] = useState("");
  const [docName, setDocName] = useState("");
  const [base64FileData, setBase64FileData] = useState("");
  // const [pdfUrl, setPdfUrl] = useState(null);
  // const [coordinate,setcoordinate]=useState()
  // const coordinates=[
  //   {"lx":619.8541589017387,"ly":-3085.238507463724,"ux":739.8465436204733,"uy":-3125.2340895703373},
  //   {"lx":377.8354310866105,"ly":-1823.419149693843,"ux":497.8278158053449,"uy":-1863.4147318004561},
  //   {"lx":135.8179291234483,"ly":-561.6061831803515,"ux":255.81031384218272,"uy":-601.6017652869646},
  //   {"lx":561.0858362702776,"ly":-2344.8700244977467,"ux":681.0782209890122,"uy":-2384.8656066043604},
  //   {"lx":319.0671084551494,"ly":-1083.050666727866,"ux":439.05949317388377,"uy":-1123.0462488344792},
  //   {"lx":77.04960649198718,"ly":178.7622997856256,"ux":197.0419912107216,"uy":138.76671767901234}]

  useEffect(() => {
    setId(companyStore.companyID);
    setDoc_id(companyStore.doc_id);
    console.log("doc_id", doc_id);
    console.log("previewing documents");

    axios
      .post(`${config.node_api_endpoint}/v1/esign/getdocument/`, { doc_id: companyStore.doc_id })
      .then((res) => {
        console.log(res.data);
        setData(res.data.signing_parties);
        setImageData(res.data.png_url);
        console.log("imagedata-->", res.data.png_url);
        setWorkFlowId(res.data.workflowId);
        setAuthToken(res.data.authToken);
      })
      .catch((er) => {
        "%o", er;
      });
  }, []);

  useEffect(() => {
    console.log("work-->", workFlowId, authToken);

    {
      if (/* workFlowId?.length > 0 ||  */ authToken?.length > 0)
        axios
          .post(`${config.node_api_endpoint}/v1/esign/DownloadWorkflowDocuments`, {
            workFlowId: workFlowId,
            authToken: authToken,
          })
          .then((res) => {
            console.log("Document preview-->", res?.data?.Response[0]?.DocumentName);
            setBase64FileData(res?.data?.Response[0]?.Base64FileData);
            setDocName(res?.data?.Response[0]?.DocumentName);
            const pdfBlob = base64ToBlob(res?.data?.Response[0]?.Base64FileData, "application/pdf");
            const pdfFileUrl = URL.createObjectURL(pdfBlob);
            setPdfUrl(pdfFileUrl);
          })
          .catch((err) => {
            console.log("%o", err);
          });
    }
  }, [workFlowId, authToken]);

  const base64ToBlob = (base64, mimeType) => {
    const byteCharacters = atob(base64); // Decode base64 string to binary
    const byteArrays = [];

    // Create byte arrays from the binary string
    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      byteArrays.push(new Uint8Array(byteNumbers));
    }

    // Create the Blob with the PDF MIME type
    return new Blob(byteArrays, { type: mimeType });
  };

  function digito2EsctackCordinates(dlx, dly, dux, duy) {
    var c1 = 1.423636485;
    var c2 = -7.422450698;
    var c3 = -1.982936128;
    var c4 = 24.98120326;

    var eslx = dlx * c1 + dly * c3;
    var esly = dlx * c2 + dly * c4;
    var esux = dux * c1 + duy * c3;
    var esuy = dux * c2 + duy * c4;
    return {
      lx: eslx,
      ly: esly,
      ux: esux,
      uy: esuy,
    };
  }

  const coordinates = [
    { x: 300, y: 540 },
    { x: 180, y: 540 },
    { x: 60, y: 540 },
    { x: 300, y: 513 },
    { x: 180, y: 513 },
    { x: 60, y: 513 },
  ];

  const handleButtonClick = (item) => {
    console.log([
      ...draggables,
      { id: item.id, name: item.name, email: item.email, x: 0, y: 0, imageId: item.imageId },
    ]);
  };

  const handleDrag = (index, e, data) => {
    setDraggables((prev) => prev.map((item, i) => (i === index ? { ...item, x: data.x, y: data.y } : item)));
  };

  const handleImageLoad = (e, imageId) => {
    const imgElement = e.target;
    setImageSizes((prev) => ({
      ...prev,
      [imageId]: {
        width: imgElement.naturalWidth / 4,
        height: imgElement.naturalHeight / 4,
      },
    }));
  };

  function esStackCordinatesToDigio(x, y) {
    console.log("in function");

    var elx = x;
    var ely = 1;
    var eux = y; //because tranformation matrix was derived with y in eux position by mistake
    var euy = 1;
    var c1 = 0.583297106247098;
    var c2 = 0.166639121;
    var c3 = 274.3495435;
    var c4 = -39.985987035;

    var dlx = 449.338661360347 - (300 - x) * 1.41666974191992;
    var dly = 10.0057451431493 - (540 - y) * -1.1854809;
    var dux = 589.329955680173 - (449.338661360347 - dlx);
    var duy = 49.9991308793456 - (10.0057451431493 - dly);
    return {
      lx: dlx,
      ly: dly,
      ux: dux,
      uy: duy,
    };
  }

  const handleSendClick = () => {
    console.log("in it");
    console.log(draggables);

    const updatedData = draggables
      .map((item) => {
        console.log("items ", item.x, item.y);

        let coordinate = esStackCordinatesToDigio(item.x, item.y);
        // console.log("hey");
        console.log("coordinates", coordinate);

        const llx = coordinate.lx;
        const lly = coordinate.ly;
        const urx = coordinate.ux;
        const ury = coordinate.uy;

        return {
          name: item.name,
          email: item.email,
          position: {
            llx: llx,
            lly: lly,
            urx: urx,
            ury: ury,
          },
        };
      })
      .filter((data) => data !== null);

    console.log("updated data", updatedData);
  };

  if (buttonClicked) {
    console.log("inclicked");

    handleSendClick();
  }

  return (
    // <div
    //   style={{
    //     display: "flex",
    //     flexWrap: "wrap",
    //     flexDirection: "row",
    //     width: "100%",
    //     display: "flex",
    //     alignItems: "center",
    //     justifyContent: "center",
    //   }}
    // >
    //   <div className={styles.imageContainer}>
    //     {/* Send Button */}

    //     {imageData &&
    //       Object.entries(imageData).map(([key, image]) => (
    //         <div
    //           key={key}
    //           ref={(el) => (containerRefs.current[image.FileId] = el)}
    //           className="outer-div"
    //           style={{
    //             position: "relative",
    //             border: "1px solid #000",
    //             margin: "20px",
    //             display: "flex",
    //             alignItems: "center",
    //             justifyContent: "center",
    //             overflow: "hidden",

    //             width: imageSizes[image.FileId]?.width || "auto",
    //             height: imageSizes[image.FileId]?.height || "auto",
    //           }}
    //         >
    //           {/* Image tag to retrieve the natural width and height */}
    //           <img
    //             src={image}
    //             alt="Background"
    //             onLoad={(e) => handleImageLoad(e, image.id)}
    //             style={{ width: "100%", height: "auto", display: "block" }}
    //           />
    //         </div>
    //       ))}
    //   </div>
    // </div>

    <div>
      {pdfUrl ? (
        // Display PDF in an iframe
        <iframe src={pdfUrl} width="100%" height="1000px" title="Document Preview"></iframe>
      ) : (
        <p>Loading document...</p>
      )}
    </div>
  );
};

export default InspectPdf;
