import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";

const AllDivisionContext = createContext([]);

export const ApiProvider = ({ children }) => {
  const [eSignCoordinates, setESignCoordinates] = useState(() => {
    const savedData = localStorage.getItem("signCoordinates");
    return savedData ? JSON.parse(savedData) : []; // Default to empty array
  });

  useEffect(() => {
    if (eSignCoordinates) {
      localStorage.setItem("signCoordinates", JSON.stringify(eSignCoordinates));
    }
  }, [eSignCoordinates]);

  return (
    <AllDivisionContext.Provider value={{ eSignCoordinates, setESignCoordinates }}>
      {children}
    </AllDivisionContext.Provider>
  );
};

export const useAllProduct = () => {
  const context = useContext(AllDivisionContext);

  if (context === null) {
    throw new Error("useAllProduct must be used within a AllProductProvider");
  }
  if (!context) {
    throw new Error("useAllProduct must be used within a ApiProvider");
  }

  return context;
};

export default AllDivisionContext;
