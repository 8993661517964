import { useCallback, useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import PropTypes from "prop-types";
import styles from "./DirectorKYC.module.css";
import config from "../../../../service/config";
import companyStore from "../../CompanyStore";
import OfflineSigantoryKyc from "../CompanyKYC/OfflineSignatoryKyc/OfflineSigantoryKyc";

const DirectorKYC = ({ className = "" }) => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [directors, setDirectors] = useState([{ uname: "", email: "", remark: "" }]);
  const [validationErrors, setValidationErrors] = useState([{}]);
  const [len, setLen] = useState(1);
  const [remark, setRemake] = useState("");
  const [compName, setCompName] = useState("");
  const [escrowName, setEscrowName] = useState("");
  const [id, setId] = useState("");
  const [mode, setMode] = useState("external");
  useEffect(() => {
    setId(companyStore.companyID);
    console.log("in director new Director", companyStore.companyID);
    setCompName(companyStore.companyName);
    setEscrowName(companyStore.escrowName);
  }, []);

  const onFrameButtonClick = useCallback(() => {
    navigate(`/content/company/kyclist/main`);
  }, [navigate, id]);

  const onFrameButtonClick1 = useCallback(() => {
    handleScrollToTop();

    const errors = validateAllDirectors();
    if (errors.some((err) => Object.keys(err).length > 0)) {
      console.log("Validation errors:", errors);
      setValidationErrors(errors);
      return;
    }

    const kycData = directors.map(({ uname, email, remark }, index) => ({
      name: uname,
      email: email,
      remark: remark,
    }));

    const { NameOfCompany, created_date, status, POCName, KYC } = data;
    const comp_info = {
      company_id: id,
      director_list: [...kycData],
      mode: "online",
    };
    console.log("Company info", comp_info);

    axios
      .post(`${config.node_api_endpoint}/v1/director/kyc_request`, comp_info)
      .then(() => {
        console.log("Data updated");
        navigate(`/content/company/requestsend`);
      })
      .catch(() => {
        console.log("Try Again");
      });
  }, [directors, data, len, id, navigate]);

  const handleInputChange = (index, field, value) => {
    const newDirectors = [...directors];
    newDirectors[index][field] = value;
    setDirectors(newDirectors);
  };

  const validateDirector = (director) => {
    const errors = {};
    if (!director.uname.trim()) errors.uname = "Name is required";
    if (!director.email.trim()) {
      errors.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(director.email)) {
      errors.email = "Invalid email format";
    }
    return errors;
  };

  const validateAllDirectors = () => {
    return directors.map((director) => validateDirector(director));
  };

  const addDirector = () => {
    let newDirectors = {};
    setDirectors([...directors, { uname: "", email: "", remark: "" }]);
    setValidationErrors([...validationErrors, {}]);
  };

  const clearDirectorFields = (index) => {
    const newDirectors = [...directors];
    newDirectors[index] = { uname: "", email: "", remark: "" };
    setDirectors(newDirectors);
    const newErrors = [...validationErrors];
    newErrors[index] = {};
    setValidationErrors(newErrors);
  };

  const removeDirector = (index) => {
    setDirectors(directors.filter((_, i) => i !== index));
    setValidationErrors(validationErrors.filter((_, i) => i !== index));
  };
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: smooth scrolling
    });
  };

  const handleMode = () => {
    setMode(!mode);
  };
  return (
    <div className={[styles.pageContainer, className].join(" ")}>
      <div className={styles.frameParent}>
        <button className={styles.arrowNarrowLeftParent} onClick={onFrameButtonClick}>
          <img className={styles.arrowNarrowLeftIcon} alt="" src="/arrownarrowleft2.svg" />
          <div className={styles.back}>Back</div>
        </button>
        {/* <b className={styles.escrowstackPrivateLimited}>{compName}</b> */}
        <b className={`${styles.escrowName} mt-4`}>{escrowName}</b>
        <b className={`${styles.compName} mt-2 mb-4`}>{compName}</b>
        {/* <div className="d-flex w-100">
          <div className="pe-5 d-flex justify-content-center align-items-center">
            <label className="form-label">
              Mode of Kyc<span style={{ color: "red" }}>*</span>
            </label>
          </div>
          <div className="mb-4 w-50 d-flex justify-content-center align-items-center">
            <select
              name="typeOfContract"
              className="form-select"
              onChange={handleMode}
            >
              <option value="Online">Online</option>
              <option value="Offline">Offline</option>
            </select>
          </div>
        </div> */}
        <div className="d-flex align-items-center w-100 mb-3">
          <div>
            <label className="d-flex align-items-center pe-4">
              Send To <span style={{ color: "red" }}>*</span>
            </label>
          </div>
          <div className="pe-3">
            <input
              type="radio"
              id="external"
              name="mode"
              value="external"
              checked={mode === "external"}
              onChange={(e) => setMode(e.target.value)}
            />
            <label className="p-2" htmlFor="external">
              Customer
            </label>
          </div>
          <div>
            <input
              type="radio"
              id="internal"
              name="mode"
              value="internal"
              checked={mode === "internal"}
              onChange={(e) => setMode(e.target.value)}
            />
            <label className="p-2" htmlFor="internal">
              Internal Team
            </label>
          </div>
        </div>
        {mode === "external" ? (
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.frameWrapper}>
                <div className={styles.directorKycParent}>
                  <div className={styles.directorKyc}>
                    <ol className={styles.directorKycList}>
                      {directors.map((director, index) => (
                        <div key={index} className={styles.frameDiv}>
                          <div className={styles.framehead}>
                            <li>Authorized Signatory KYC</li>
                            <div className={styles.buttons}>
                              <button className={styles.input} onClick={() => clearDirectorFields(index)}>
                                Clear
                              </button>

                              {index > 0 && (
                                <button className={styles.remove} onClick={() => removeDirector(index)}>
                                  REMOVE
                                </button>
                              )}
                            </div>
                          </div>
                          <div className={styles.mobileMenuParent}>
                            <TextField
                              className={styles.mobileMenu1}
                              color="primary"
                              label={`Signatory Name ${index + 1}`}
                              variant="outlined"
                              value={director.uname}
                              onChange={(e) => handleInputChange(index, "uname", e.target.value)}
                              sx={{ "& .MuiInputBase-root": { height: "56px" } }}
                              error={!!validationErrors[index]?.uname}
                              helperText={validationErrors[index]?.uname}
                            />
                            <div className={styles.mobileMenu1}>
                              <TextField
                                className={styles.field}
                                color="primary"
                                label={`Signatory Email ID ${index + 1}`}
                                variant="outlined"
                                type="email"
                                value={director.email}
                                onChange={(e) => handleInputChange(index, "email", e.target.value)}
                                sx={{
                                  "& .MuiInputBase-root": { height: "56px" },
                                  width: "344px",
                                  fontSize: "16px",
                                }}
                                error={!!validationErrors[index]?.email}
                                helperText={validationErrors[index]?.email}
                              />
                            </div>
                          </div>

                          <div className={styles.mobileMenu3}>
                            <textarea
                              className={styles.field1}
                              placeholder="Remark"
                              type="text"
                              value={director.remark}
                              onChange={(e) => handleInputChange(index, "remark", e.target.value)}
                            />
                          </div>
                        </div>
                      ))}
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${styles.loadsButtonsParent} mt-4`}>
              <button
                className={styles.loadsButtons}
                onClick={() => {
                  addDirector();
                }}
              >
                <img className={styles.arrowNarrowLeftIcon} alt="" src="/pluscircle3.svg" />
                <b className={styles.addDirector}>Add Signatory</b>
              </button>
              <button className={styles.loadsButtonsWrapper} onClick={onFrameButtonClick1}>
                <div className={styles.loadsButtons1}>
                  <b className={styles.generateRequest}>Generate Request</b>
                </div>
              </button>
            </div>
          </div>
        ) : (
          <OfflineSigantoryKyc />
        )}
      </div>
    </div>
  );
};

DirectorKYC.propTypes = {
  className: PropTypes.string,
};

export default DirectorKYC;
