import PropTypes from "prop-types";
import styles from "./CompanyContainer.module.css";
import { Fragment, useEffect, useState } from "react";
import Axios from "axios";
import NewCompanyModel from "./AddCompany/NewCompanyModel";
import config from "../../../../service/config";
import { TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useNavigate } from "react-router-dom";
import companyStore from "../../CompanyStore";
import { Spin } from "antd";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Pagination } from "antd";
const PageContainer = ({ className = "" }) => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [showModel, setShowModel] = useState(false);
  const [showActivity, setShowActivity] = useState(false);
  const [currentcompid, setCurrentCompId] = useState("");
  const [createdDateFilter, setCreatedDateFilter] = useState(null);
  const [textFilter, setTextFilter] = useState("");
  const [currentCompName, setCurrentCompName] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    setLoader(true);
    const payload = {
      escrow_id: companyStore.escrowId,
    };

    // GET -> Company list.
    Axios.post(`${config.node_api_endpoint}/v2/company/list`, payload)
      .then((res) => {
        const sortedData = res.data.sort((a, b) => {
          const dateA = dayjs(a.created_at, "DD MMM YYYY / hh:mm a");
          const dateB = dayjs(b.created_at, "DD MMM YYYY / hh:mm a");
          return dateB - dateA;
        });
        setData(sortedData);
        setFilteredData(sortedData);
        setLoader(false);
      })
      .catch((er) => {
        console.log(er);
      });
  }, [showModel]);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const resetFilters = () => {
    setFilteredData(data);
  };

  const applyFilters = () => {
    let filtered = data;

    // if (statusFilter) {
    //   filtered = filtered.filter((item) => item.status === statusFilter);
    // }

    // if (createdDateFilter) {
    //   filtered = filtered.filter(
    //     (item) => new Date(item.created_at).toDateString() === new Date(createdDateFilter).toDateString(),
    //   );
    // }
    if (createdDateFilter) {
      filtered = filtered.filter((item) => {
        const [datePart] = item.created_at.split(" / "); // Extract date part
        const itemDate = dayjs(datePart, "DD MMM YYYY").format("YYYY-MM-DD"); // Parse and format
        const filterDate = dayjs(createdDateFilter).format("YYYY-MM-DD");

        console.log("Parsed itemDate:", itemDate, "Parsed filterDate:", filterDate);

        return itemDate === filterDate;
      });
    }

    if (textFilter) {
      filtered = filtered.filter((item) => item.status === textFilter);
    }

    setFilteredData(filtered);
    setCurrentPage(1);
  };

  const handleTextFilterChange = (e) => {
    const value = e.target.value;
    setTextFilter(value);

    let filtered = data;

    if (value) {
      filtered = filtered.filter(
        (item) =>
          item.created_at.toLowerCase().includes(value.toLowerCase()) ||
          item.name.toLowerCase().includes(value.toLowerCase()),
      );
    }

    setFilteredData(filtered);
  };

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const currentPageData = filteredData.slice(startIndex, startIndex + pageSize);

  return (
    <>
      <div className={[styles.pageContainer, className].join(" ")}>
        <Spin
          size="large"
          tip="Loading..."
          spinning={loader}
          className="d-flex justify-content-center align-items-center"
        >
          <div className={styles.newCompany_div}>
            <b className={styles.escrowName}>{companyStore.escrowName}</b>
            <button
              onClick={() => {
                setShowModel(true);
              }}
              className={styles.newCompany}
            >
              <img className={styles.plusCircleIcon} alt="" src="/pluscircle.svg" />
              <b className={styles.addNewCompany}>New Company</b>
            </button>
          </div>

          <div className={styles.searchFiltersParent}>
            <div className={styles.frameParent}>
              {/* MUI search for table */}
              <div className={styles.leadingIconParent}>
                <img className={styles.leadingIcon} alt="" src="/leadingicon.svg" />
                <TextField label="Search" variant="standard" value={textFilter} onChange={handleTextFilterChange} />
              </div>

              <div className={styles.mobileMenuParent}>
                {/* mui date and time */}
                {/* <div className={styles.mobileMenu}>
              <div className={styles.field}>
                <div className={styles.content}>
                  <div className={styles.floatingLabel}>
                    <div className={styles.label}>{`Created date `}</div>
                  </div>
                  <div className={styles.input}>
                    <div className={styles.input1}>28/08/2024</div>
                    <div className={styles.input1}>12:59AM</div>
                    <img
                      className={styles.cursorIcon}
                      alt=""
                      src="/cursor.svg"
                    />
                  </div>
                </div>
              </div>
              <img
                className={styles.mobileMenuChild}
                alt=""
                src="/trailingicon-2.svg"
              />
            </div> */}
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="Created Date"
                    value={createdDateFilter}
                    onChange={(newValue) => setCreatedDateFilter(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                    sx={{ "& .MuiInputBase-root": { width: "250px", borderRadius: "12px" } }}
                  />
                </LocalizationProvider> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Created Date"
                    value={createdDateFilter}
                    onChange={(newValue) => setCreatedDateFilter(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                    sx={{
                      "& .MuiInputBase-root": {
                        width: "250px",
                        borderRadius: "12px",
                      },
                    }}
                  />
                </LocalizationProvider>

                {/* mui filter for table */}
                {/* <div className={styles.mobileMenu}>
              <div className={styles.field}>
                <div className={styles.content}>
                  <div className={styles.floatingLabel}>
                    <div className={styles.label}>Status</div>
                  </div>
                  <div className={styles.input3}>
                    <div className={styles.input1}>Requested</div>
                    <img
                      className={styles.cursorIcon}
                      alt=""
                      src="/boxarrowindown3.svg"
                    />
                  </div>
                </div>
              </div>
              <img className={styles.mobileMenuChild} alt="hey" src="/arrow14.svg" />
            </div> */}

                {/* button to apply filter to table */}
                <div className={styles.loadsButtons1} onClick={applyFilters}>
                  <img className={styles.boxArrowInDownIcon} alt="" src="/boxarrowindown1.svg" />
                  <b className={styles.addNewCompany}>Apply</b>
                </div>
              </div>

              {/* Frame Container ends */}
            </div>
          </div>

          <div className={`${styles.frameGroup}`} style={{ overflow: "hidden" }}>
            <table className={styles.table}>
              <thead
                className={styles.theadcontain}
                style={{
                  borderBottom: "1px solid #7575757F",
                  padding: "16px 60px 16px 32px",
                  height: "20px",
                  lineHeight: "35px",
                  overflow: "hidden",
                }}
              >
                <tr>
                  <th colSpan={3}>
                    <span style={{ fontSize: "24px", padding: "5px", fontWeight: "600", margin: "5px" }}>
                      Company List
                    </span>
                  </th>
                </tr>
              </thead>
              <thead className={styles.theadcontain}>
                <tr className={styles.trheaderContent}>
                  <th>Created Date</th>
                  <th>Company Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className={styles.tbodycontain}>
                {currentPageData && currentPageData?.length > 0 ? (
                  currentPageData.map((detail) => {
                    return (
                      <tr key={detail._id}>
                        <td>{detail.created_at}</td>
                        <td>{detail.name}</td>
                        {/* <td>{detail.status}</td> */}
                        <td>
                          <button
                            className={styles.change}
                            onClick={() => {
                              handleScrollToTop();
                              setCurrentCompId(detail._id);
                              setCurrentCompName(detail.name);
                              companyStore.setCompanyID(detail._id);
                              companyStore.setCompanyName(detail.name);
                              console.log(detail._id);
                              navigate("/content/company/kyclist/main");
                            }}
                          >
                            View More
                          </button>
                        </td>
                        {/* `company/${detail.id}` */}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="3" className={styles.noRecords}>
                      No Records Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-end mt-4">
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={filteredData.length}
              onChange={handlePaginationChange}
              // showSizeChanger
            />
          </div>
          <NewCompanyModel
            isVisible={showModel}
            setShowModel={setShowModel}
            onclose={() => {
              setShowModel(false);
            }}
          />
        </Spin>
      </div>
    </>
  );
};

PageContainer.propTypes = {
  className: PropTypes.string,
};

export default PageContainer;
