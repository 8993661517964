import React, { Component } from "react";
export default class FileInput extends React.Component {
  constructor(props) {
    super(props);
    this.uploadFile = this.uploadFile.bind(this);
  }

  uploadFile(event) {
    let file = event.target.files[0];
    console.log(file);

    if (file) {
      var fileReader = new FileReader();
      fileReader.onload = (e) => {
        this.props.handleFileUpload(e.target.result);
      };
      fileReader.readAsDataURL(file);
      // axios.post('/files', data)...
    }
  }

  render() {
    return (
      <span>
        <input type="file" name="myFile" onChange={this.uploadFile} />
      </span>
    );
  }
}
