import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import config from "../../../../service/config";
import styles from "./esignWithEstamp.module.css";
import companyStore from "../../CompanyStore";
import { useAllProduct } from "../../../api-context/commonApiContext";
export const EsignWithEstamp = () => {
  const { eSignCoordinates } = useAllProduct();
  const location = useLocation();
  const doc_id = location.pathname.split("/")[4];
  const [esignData, setEsignData] = useState([]);
  const [img, setimg] = useState([]);
  const [escrow_id, setEscrow_id] = useState([]);
  const [contract_id, setContract_id] = useState([]);
  const [draggables, setDraggables] = useState([]);
  const [kycInfo, setKycInfo] = useState([]);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [estampData, setEstampData] = useState({
    typeOfContract: "Operational",
    firstparty: "",
    state: "",
    secondparty: "",
    descriptionofDocument: "",
    articleCode: "",
    stampDutyAmount: "",
    considerationPrice: "",
    prtcptentty: [], // Initialize parties as an empty array
  });

  const lglcnstn = [
    "Resident Individual",
    "Public Ltd",
    "Private Ltd",
    "LLP",
    "Proprietorship",
    "Partnership",
    "Entity Created by Statute",
    "Trust",
    "HUF",
    "Co-op Society",
    "Association of Persons",
    "Government",
    "Self Help Group",
    "Non-Resident",
    "Foreign Company",
  ];

  const docType = ["Indian Entity", "Resident Individual", "Foreign Entity", "NRI/Foreign Individual"];

  const reltocntrct = ["Debtor", "Guarantor", "Co-Obligant", "Creditor", "Beneficiary"];

  const articleCodeOptions = {
    RJ: [
      { value: "1072", description: "Bond" },
      { value: "1079", description: "Bank Guarantee" },
      { value: "1080", description: "Letter of License" },
      { value: "1141", description: "Respondentia Bond" },
      { value: "1104", description: "Affidavit" },
      { value: "1151", description: "Loan Agreement" },
      { value: "1367", description: "Re-conveyance of mortgaged consideration less Rs.1000" },
      { value: "1662", description: "Hypothecation Deed" },
      { value: "1802", description: "Security Bond" },
      {
        value: "1579",
        description: "Composition Deed Whereby he conveys his property for the benefit of his creditors.",
      },
      { value: "1583", description: "Composition Deed in any other case" },
      { value: "1167", description: "Simple Agreement" },
      { value: "1685", description: "Indemnity bond" },
    ],
    KA: [
      { value: "0004", description: "Affidavit" },
      { value: "0116", description: "Power of Attorney - for authorising more than 5 person to act jointly" },
      { value: "0090", description: "Power of Attorney - Authorizing to sell property" },
      { value: "0092", description: "Power of Attorney - when sale power given to other than family members" },
      { value: "0022", description: "Agreement (in any other cases)" },
      { value: "0023", description: "Agreement relating to DTD - Loan amount does not exceed Rs.10 lakhs" },
      { value: "0024", description: "Agreement relating to DTD - Loan amount exceeding Rs.10 lakhs" },
      {
        value: "0025",
        description: "Pawn or Pledge of Movable Property - Loan amount above Rs.1 lakh and upto Rs.10 lakh",
      },
      { value: "0026", description: "Pawn or Pledge of Movable Property - Loan amount above Rs.10 lakhs" },
      { value: "0128", description: "Acknowledgement of aDebt- uptoRs.5000/-" },
      { value: "0129", description: "Acknowledgement of a Debt-more - than 5000/-" },
      { value: "0068", description: "Mortgage Deed - Hypothecation of movable property loan upto Rs.10 lakh" },
      { value: "0069", description: "Mortgage Deed - loan exceeding 10 lakhs" },
      { value: "0054", description: "Indemnity Bond (As per Article 47)" },
      { value: "0127", description: "Bank Guarantee - If relating to e-bank guarantee" },
      {
        value: "0055",
        description: "Lease of Immovable Property - Not exceeding 1 year incase of Residential property",
      },
      {
        value: "0056",
        description: "Lease of Immovable Property - Not exceeding 1 year in case of commercial industrial property",
      },
      { value: "0027", description: "Appointment in execution of a power" },
      { value: "0032", description: "Bond - Amount secured does not exceed Rs. .1000" },
      { value: "0033", description: "Bond - Amount exceeding Rs. 1000" },
    ],
  };

  useEffect(() => {
    const fetchData = async () => {
      const payload = {
        escrow_id: companyStore.escrowId,
      };

      try {
        const response = await axios.post(`${config.node_api_endpoint}/v2/company/list`, payload);
        const data = response.data;
        const director_ids = data[0].director_ids;
        console.log("directorData--->", director_ids);

        const kycResponse = await axios.post(`${config.node_api_endpoint}/v2/company/kycparties`, { director_ids });
        // setKycResponseData(kycResponse.data);
        let filteredData = kycResponse.data.filter((data) => data.status === "approved");
        console.log("filtered", filteredData);
        setKycInfo(filteredData);

        //   setParties(
        //     filteredData.map((detail) => {
        //     console.log("kycData--->", detail);
        //     return {
        //       name: detail.director_email,
        //       email: detail.director_name,
        //     };
        //   }),
        // );
        console.log("kycResponse--->", kycResponse.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [companyStore]);

  const coordinates = [
    { x: 290, y: 475 },
    { x: 180, y: 540 },
    { x: 60, y: 540 },
    { x: 300, y: 513 },
    { x: 180, y: 513 },
    { x: 60, y: 513 },
  ];

  useEffect(() => {
    axios.post(`${config.node_api_endpoint}/v1/esign/getdocument/`, { doc_id }).then((res) => {
      console.log("data====", res.data);
      setimg(res.data.png_url);
      setEsignData(res.data.signing_parties);
      var draggableArray = [];
      for (var i = 0; i < res?.data?.signing_parties?.length; i++) {
        var esignDataItem = res?.data?.signing_parties[i];
        var line_no = Math.floor(i / 4);
        draggableArray.push({
          name: esignDataItem.name,
          email: esignDataItem.email,
          x: coordinates[0].x - (i % 4) * 100,
          y: coordinates[0].y - line_no * 27,
          imageId: esignDataItem.imageId,
        });
      }
      setDraggables(draggableArray);
    });
  }, [doc_id]);

  // Update parties whenever esignData changes
  // useEffect(() => {
  //   const initialParties = esignData.map((d, index) => {
  //     const panDetails = kycInfo.find((info) => info.director_email === d.email);
  //     return {
  //       prtcptenttyId: index + 1,
  //       lglcnstn: "",
  //       reltocntrct: "",
  //       fulnm: d.name,
  //       cntrprtycntmobno: "",
  //       emlid: d.email,
  //       panno: panDetails?.kyc_details.actions[0].details.pan.id_number || "",
  //       partytyp: "",
  //       doi:panDetails?.kyc_details?.actions[0]?.details?.pan.dob.length>0? convertDate(panDetails?.kyc_details?.actions[0]?.details?.pan?.dob) || "" :"",
  //       // doi: panDetails?.kyc_details.actions[0].details.pan.dob || "",
  //     };
  //   });
  //   setEstampData((prevData) => ({
  //     ...prevData,
  //     prtcptentty: initialParties,
  //   }));
  // }, [esignData, kycInfo]);

  useEffect(() => {
    const sortedParties = esignData
      .sort((a, b) => {
        if (a.name === estampData.firstparty && b.name !== estampData.firstparty) {
          return -1;
        } else if (a.name !== estampData.firstparty && b.name === estampData.firstparty) {
          return 1;
        }

        if (a.name === estampData.secondparty && b.name !== estampData.secondparty) {
          return -1;
        } else if (a.name !== estampData.secondparty && b.name === estampData.secondparty) {
          return 1;
        }

        return 0;
      })
      .map((d, index) => {
        const panDetails = kycInfo.find((info) => info.director_email === d.email);
        return {
          prtcptenttyId: index + 1,
          fulnm: d.name,
          reltocntrct: "",
          emlid: d.email,
          cntrprtycntmobno: "",
          panno: panDetails?.kyc_details.actions[0].details.pan.id_number || "",
          doi:
            panDetails?.kyc_details?.actions[0]?.details?.pan.dob.length > 0
              ? convertDate(panDetails?.kyc_details?.actions[0]?.details?.pan?.dob) || ""
              : "",
          lglcnstn: "",
          partytyp: "",
        };
      });

    setEstampData((prevData) => ({
      ...prevData,
      prtcptentty: sortedParties, // Update with sorted parties
    }));
  }, [esignData, kycInfo, estampData.firstparty, estampData.secondparty]); // Ensure the hook re-runs when these values change

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEstampData({ ...estampData, [name]: value });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" })); // Clear error
  };

  const handlePartyChange = (e, index) => {
    const { name, value } = e.target;
    console.log("handle-->", name, value);
    const updatedParties = [...estampData.prtcptentty];
    console.log("updated-->", updatedParties);

    updatedParties[index] = { ...updatedParties[index], [name]: value };
    setEstampData({ ...estampData, prtcptentty: updatedParties });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [`prtcptentty[${index}].${name}`]: "",
    }));
  };
  console.log("estampData-->", esignData);

  // let eCoordinates = [];
  // const conversion = (x, y, pg, user) => {
  //   console.log("cordiantes-->",x, y);

  //   eCoordinates.push({
  //     PageNo: pg,
  //     ControlID: 4,
  //     AssignedTo: user,
  //     Top: (785 - Math.floor(y * 1.586) > 785 ? 770 : 785 - Math.floor(y * 1.586))-10, //ul
  //     Left: Math.floor(x * 1.586) > 440 ? 440 : Math.floor(x * 1.586), //ul
  //     Width: Math.floor(x * 1.586) + 90 > 600 ? 600 : Math.floor(x * 1.586) + 90,
  //     // Height: 695-( Math.floor(y * 1.586) > 776 ? 770 : Math.floor(y * 1.586))-45,//lr
  //     Height: 785 - (Math.floor(y * 1.586) - 60) > 785 ? 785 : 785 - (Math.floor(y * 1.586)-60), //lr
  //   });
  //   // eCoordinates.push({
  //   //   PageNo: pg,
  //   //   ControlID: 4,
  //   //   AssignedTo: user,
  //   //   Left: Math.floor(x * 1.586) > 466 ? 460 : Math.floor(x * 1.586),
  //   //   Top: 76,
  //   //   Height: Math.floor(y * 1.586) > 776 ? 770 : Math.floor(y * 1.586),
  //   //   Width: 130,
  //   // });
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    // if (validateForm()) {
    // const signCoordinates = {};
    // draggables.forEach((item, ind) => {
    //   console.log("item", item);
    //   signCoordinates[item.email] = {};
    //   img.map((value, index) => {
    //     signCoordinates[item.email][index + 1] = [conversion(item.x, item.y, index + 1, ind + 1)];
    //   });
    // });
    let SignatureSettings = {
      ModeOfAuthentication: 0,
      ModeOfSignature: "12",
    };
    const payload = {
      regType: "1",
      signFlag: "0",
      estampFlag: "Y",
      f2f: "Y",
      state: estampData.state,
      estampdtls: {
        firstparty: estampData.firstparty,
        secondparty: estampData.secondparty,
        stampDutyAmount: estampData.stampDutyAmount,
        considerationPrice: estampData.considerationPrice,
        descriptionofDocument: estampData.descriptionofDocument,
        articleCode: estampData.articleCode,
      },
      prtcptentty: estampData.prtcptentty,
      scrtydtls: null,
    };
    axios
      .post(`${config.node_api_endpoint}/v2/estamp/update`, { doc_id, payload, reason: "E-SignWithE-Stamp" })
      .then((res) => {
        if (res.status === 200) {
          return axios.post(`${config.node_api_endpoint}/v1/esign/sign_document`, {
            doc_id,
            sign_coordinates: eSignCoordinates,
            SignatureSettings: SignatureSettings,
          });
        } else {
          throw new Error("Failed to update e-stamp");
        }
      })
      .then((signRes) => {
        if (signRes.status === 200) {
          const urlToOpen = signRes.data.URL;

          const popupWindow = window.open(urlToOpen, "_blank", "width=800,height=600");

          setTimeout(() => {
            if (popupWindow) {
              popupWindow.close();
            }
          }, 10000);
          localStorage.removeItem("signCoordinates");
          navigate(`/content/esign/esign-requested`);
        } else {
          throw new Error("Failed to sign document");
        }
      })
      .catch((err) => {
        alert(err.message || "An error occurred");
      });
    // }
  };

  const validateForm = () => {
    const newErrors = {};

    // Basic field validations
    if (!estampData.firstparty) newErrors.firstparty = "First Party Name is required";
    if (!estampData.state) newErrors.state = "State is required";
    if (!estampData.secondparty) newErrors.secondparty = "Second Party Name is required";
    if (!estampData.descriptionofDocument) newErrors.descriptionofDocument = "Description is required";
    if (!estampData.articleCode) newErrors.articleCode = "Article Code is required";

    // Validate Stamp Duty Amount
    if (!estampData.stampDutyAmount) {
      newErrors.stampDutyAmount = "Stamp Duty Amount is required";
    } else if (estampData.stampDutyAmount < 10) {
      newErrors.stampDutyAmount = "Stamp Duty Amount must be greater than 9";
    } else if (estampData.stampDutyAmount.toString().length > 19) {
      newErrors.stampDutyAmount = "Stamp Duty Amount must not exceed 19 characters";
    }

    // Validate Consideration Price
    if (!estampData.considerationPrice) {
      newErrors.considerationPrice = "Consideration Price is required";
    } else if (estampData.considerationPrice.toString().length > 19) {
      newErrors.considerationPrice = "Consideration Price must not exceed 19 characters";
    }

    // Validate unique "Creditor" and "Debtor" roles
    const roles = estampData.prtcptentty.map((party) => party.reltocntrct);
    const creditorCount = roles.filter((role) => role === "Creditor").length;
    const debtorCount = roles.filter((role) => role === "Debtor").length;

    if (creditorCount !== 1) {
      alert("There must be exactly one Creditor.");
      newErrors.creditor = "There must be exactly one Creditor.";
    }
    if (debtorCount !== 1) {
      alert("There must be exactly one Debtor.");
      newErrors.debtor = "There must be exactly one Debtor.";
    }

    // Validate prtcptentty (party) fields
    estampData.prtcptentty.forEach((party, index) => {
      if (!party.lglcnstn) newErrors[`prtcptentty[${index}].lglcnstn`] = "Legal Constitution is required";
      if (!party.reltocntrct) newErrors[`prtcptentty[${index}].reltocntrct`] = "Relationship to Contract is required";
      if (!party.cntrprtycntmobno) {
        newErrors[`prtcptentty[${index}].cntrprtycntmobno`] = "Mobile number is required";
      } else if (!/^[6-9]\d{9}$/.test(party.cntrprtycntmobno)) {
        newErrors[`prtcptentty[${index}].cntrprtycntmobno`] =
          "Mobile number must be a valid 10-digit Indian number starting with 6-9";
      }
      if (!party.panno) {
        newErrors[`prtcptentty[${index}].panno`] = "PAN number is required";
      } else if (party.panno.length !== 10) {
        newErrors[`prtcptentty[${index}].panno`] = "PAN number must be exactly 10 characters";
      } else if (!/^[A-Z]{5}[0-9]{4}[A-Z]$/.test(party.panno)) {
        newErrors[`prtcptentty[${index}].panno`] = "PAN number format is invalid (e.g., ABCDE1234F)";
      }
      if (!party.partytyp) newErrors[`prtcptentty[${index}].partytyp`] = "Party Type is required";
      if (!party.doi) newErrors[`prtcptentty[${index}].doi`] = "DOB is required";
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  console.log("escrow-->", eSignCoordinates);

  function convertDate(str) {
    const [day, month, year] = str?.split("/"); // Split the string by "/"
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  return (
    <div className="container mt-4">
      <div
        className={styles.arrowNarrowLeftParent}
        onClick={() => {
          navigate(-1);
        }}
      >
        <img className={styles.arrowNarrowLeftIcon} alt="" src="/arrownarrowleft.svg" />
        <div className={styles.back}>Back</div>
      </div>
      <form onSubmit={handleSubmit}>
        <h4>Type of Contract</h4>
        <div className="mb-4 w-50">
          <select
            name="typeOfContract"
            className="form-select"
            value={estampData.typeOfContract}
            onChange={handleChange}
            disabled={"true"}
          >
            <option value="Operational">Operational</option>
            {/* <option value="Financial">Financial</option> */}
          </select>
        </div>

        <h4>E-stamp Sections</h4>
        <div className="row mb-3">
          <div className="d-flex justify-content-between col-md-6">
            <label className="form-label">
              First Party Name<span style={{ color: "red" }}>*</span>
            </label>
            <div className="w-50">
              <select
                name="firstparty"
                className="form-select"
                value={estampData.firstparty}
                onChange={handleChange}
                style={{ borderRadius: "12px", borderColor: errors.firstparty ? "#B3261E" : "" }}
              >
                <option value="">Select First Party Name</option>
                {esignData.map((party, index) => (
                  <option key={index} value={party.name}>
                    {party.name}
                  </option>
                ))}
              </select>
              {errors.firstparty && <div style={{ color: "#B3261E" }}>{errors.firstparty}</div>}
            </div>
          </div>
          <div className="d-flex justify-content-between col-md-6">
            <label className="form-label">
              State<span style={{ color: "red" }}>*</span>
            </label>
            <div className="w-50">
              <select
                name="state"
                className="form-select"
                value={estampData.state}
                onChange={handleChange}
                style={{ borderRadius: "12px", borderColor: errors.state ? "#B3261E" : "" }}
              >
                <option>Please Select</option>
                <option value={"RJ"}>Rajasthan</option>
                <option value={"KA"}>Karnataka</option>
              </select>
              {errors.state && <div style={{ color: "#B3261E" }}>{errors.state}</div>}
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="d-flex justify-content-between col-md-6">
            <label className="form-label">
              Second Party Name<span style={{ color: "red" }}>*</span>
            </label>
            <div className="w-50">
              <select
                name="secondparty"
                className="form-select"
                value={estampData.secondparty}
                onChange={handleChange}
                style={{ borderRadius: "12px", borderColor: errors.secondparty ? "#B3261E" : "" }}
              >
                <option value="">Select Second Party Name</option>
                {esignData
                  .filter((party) => party.name !== estampData.firstparty)
                  .map((party, index) => (
                    <option key={index} value={party.name}>
                      {party.name}
                    </option>
                  ))}
              </select>
              {errors.secondparty && <div style={{ color: "#B3261E" }}>{errors.secondparty}</div>}
            </div>
          </div>
          <div className="d-flex justify-content-between col-md-6">
            <label className="form-label">
              Description of Document<span style={{ color: "red" }}>*</span>
            </label>
            <div className="w-50">
              <input
                type="text"
                className="form-control"
                name="descriptionofDocument"
                value={estampData.descriptionofDocument}
                onChange={handleChange}
                placeholder="Enter Description"
                style={{ borderRadius: "12px", borderColor: errors.descriptionofDocument ? "#B3261E" : "" }}
              />
              {errors.descriptionofDocument && <div style={{ color: "#B3261E" }}>{errors.descriptionofDocument}</div>}
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-4">
            <label className="form-label">
              Article Code<span style={{ color: "red" }}>*</span>
            </label>
            <select
              name="articleCode"
              className="form-select"
              value={estampData.articleCode}
              onChange={handleChange}
              style={{ borderRadius: "12px", borderColor: errors.articleCode ? "#B3261E" : "" }}
            >
              <option>Please Select</option>
              {/* <option value={"1104"}>1104</option> */}
              {articleCodeOptions[estampData.state]?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.description}
                </option>
              ))}
            </select>
            {errors.articleCode && <div style={{ color: "#B3261E" }}>{errors.articleCode}</div>}
          </div>
          <div className="col-md-4">
            <label className="form-label">
              Stamp Duty Amount<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="number"
              className="form-control"
              name="stampDutyAmount"
              value={estampData.stampDutyAmount}
              onChange={handleChange}
              placeholder="Enter Stamp Duty Amount"
              style={{ borderRadius: "12px", borderColor: errors.stampDutyAmount ? "#B3261E" : "" }}
            />
            {errors.stampDutyAmount && <div style={{ color: "#B3261E" }}>{errors.stampDutyAmount}</div>}
          </div>
          <div className="col-md-4">
            <label className="form-label">
              Consideration Price<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="number"
              className="form-control"
              name="considerationPrice"
              value={estampData.considerationPrice}
              onChange={handleChange}
              placeholder="Enter Consideration Price"
              style={{ borderRadius: "12px", borderColor: errors.considerationPrice ? "#B3261E" : "" }}
            />
            {errors.considerationPrice && <div style={{ color: "#B3261E" }}>{errors.considerationPrice}</div>}
          </div>
        </div>

        <h4>E-stamp Participant Details</h4>
        {/* {esignData.map((party, index) => { */}
        {estampData.prtcptentty.map((party, index) => {
          // Find the corresponding PAN info by matching the director_email
          const panDetails = kycInfo.find((info) => info.director_email === party.email);

          // console.log("party-->", panDetails);

          return (
            <div key={index}>
              <div className="d-flex justify-content-between">
                <h4>Party {index + 1}</h4>
              </div>
              <div className="row mb-3">
                <div className="d-flex justify-content-between col-md-6">
                  <label className="form-label">
                    Legal Constitution of the Party<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="w-50">
                    <select
                      name="lglcnstn"
                      className="form-select"
                      onChange={(e) => handlePartyChange(e, index)}
                      style={{
                        borderRadius: "12px",
                        borderColor: errors[`prtcptentty[${index}].lglcnstn`] ? "#B3261E" : "",
                      }}
                    >
                      <option>Please Select</option>
                      {/* Replace with your actual legal constitution options */}
                      {lglcnstn.map((type, idx) => (
                        <option key={idx} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                    {errors[`prtcptentty[${index}].lglcnstn`] && (
                      <div style={{ color: "#B3261E" }}>{errors[`prtcptentty[${index}].lglcnstn`]}</div>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-between col-md-6">
                  <label className="form-label">
                    Relationship of Party<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="w-50">
                    <select
                      name="reltocntrct"
                      className="form-select"
                      // Set the initial value or default selection here (optional)
                      // value={party.reltocntrct || ""}
                      onChange={(e) => handlePartyChange(e, index)}
                      style={{ borderRadius: "12px", borderColor: errors[`prtcptentty.reltocntrct`] ? "#B3261E" : "" }}
                    >
                      <option>Please Select</option>
                      {/* Replace with your actual relationship options */}
                      {reltocntrct.map((type, idx) => (
                        <option key={idx} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                    {errors[`prtcptentty.reltocntrct`] && (
                      <div style={{ color: "#B3261E" }}>{errors[`prtcptentty.reltocntrct`]}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="d-flex justify-content-between col-md-6">
                  <label className="form-label">
                    Contact Person Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="w-50">
                    <input
                      type="text"
                      className="form-control"
                      name="fulnm"
                      value={party.fulnm}
                      placeholder="Enter Contact Name"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between col-md-6">
                  <label className="form-label">
                    Mobile<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="w-50">
                    <input
                      type="number"
                      className="form-control"
                      name="cntrprtycntmobno"
                      onChange={(e) => handlePartyChange(e, index)}
                      placeholder="Enter Mobile Number"
                      style={{
                        borderRadius: "12px",
                        borderColor: errors[`prtcptentty[${index}].cntrprtycntmobno`] ? "#B3261E" : "",
                      }}
                    />
                    {errors[`prtcptentty[${index}].cntrprtycntmobno`] && (
                      <div style={{ color: "#B3261E" }}>{errors[`prtcptentty[${index}].cntrprtycntmobno`]}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="d-flex justify-content-between col-md-6">
                  <label className="form-label">
                    Email<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="w-50">
                    <input
                      type="text"
                      className="form-control"
                      name="emlid"
                      value={party.emlid}
                      placeholder="Enter Email Address"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between col-md-6">
                  <label className="form-label">
                    PAN<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="w-50">
                    <input
                      type="text"
                      className="form-control"
                      name="panno"
                      // value={
                      //   panDetails?.kyc_details.actions[0].details.pan.id_number?.length > 0
                      //     ? panDetails?.kyc_details.actions[0].details.pan.id_number
                      //     : party.panno
                      // }
                      // value={panDetails?.kyc_details.actions[0].details.pan.id_number || ""}
                      value={party?.panno}
                      onChange={(e) => handlePartyChange(e, index)}
                      placeholder="Enter PAN"
                      style={{
                        borderRadius: "12px",
                        borderColor: errors[`prtcptentty[${index}].panno`] ? "#B3261E" : "",
                      }}
                    />
                    {errors[`prtcptentty[${index}].panno`] && (
                      <div style={{ color: "#B3261E" }}>{errors[`prtcptentty[${index}].panno`]}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="d-flex justify-content-between col-md-6">
                  <label className="form-label">
                    Party Type<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="w-50">
                    <select
                      name="partytyp"
                      className="form-select"
                      value={party.partytyp}
                      onChange={(e) => handlePartyChange(e, index)}
                      style={{
                        borderRadius: "12px",
                        borderColor: errors[`prtcptentty[${index}].partytyp`] ? "#B3261E" : "",
                      }}
                    >
                      <option>Please Select</option>
                      {docType.map((type, idx) => (
                        <option key={idx} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                    {errors[`prtcptentty[${index}].partytyp`] && (
                      <div style={{ color: "#B3261E" }}>{errors[`prtcptentty[${index}].partytyp`]}</div>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-between col-md-6">
                  <label className="form-label">
                    Date of Birth<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="w-50">
                    <input
                      type="text"
                      className="form-control"
                      name="doi"
                      placeholder="YYYY-MM-DD"
                      value={party?.doi}
                      // value={party.doi}
                      onChange={(e) => handlePartyChange(e, index)}
                      // placeholder="Enter PAN"
                      style={{
                        borderRadius: "12px",
                        borderColor: errors[`prtcptentty[${index}].panno`] ? "#B3261E" : "",
                      }}
                      // disabled={true}
                    />
                    {errors[`prtcptentty[${index}].panno`] && (
                      <div style={{ color: "#B3261E" }}>{errors[`prtcptentty[${index}].panno`]}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <div className="d-flex justify-content-end">
          <button type="submit" className="btn btn-primary mt-3 mb-2 w-25">
            Proceed
          </button>
        </div>
      </form>
    </div>
  );
};
