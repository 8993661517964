import PropTypes from "prop-types";
import styles from "./offlineSubmission.module.css";
import Lottie from "lottie-react";
import approved from "./Animation - Generated Request GIF.json";
import { useNavigate, useParams } from "react-router-dom";

const OfflineSubmission = ({ className = "" }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  return (
    <div className={[styles.pageContainer, className].join(" ")}>
      <div className={styles.httpslottiefilescomanimatParent}>
        <Lottie className={styles.httpslottiefilescomanimatIcon} animationData={approved} loop={true} />
        <div className={styles.successfullyGeneratedRequest}>KYC Submitted Successfully</div>
        <button
          className={styles.returnToKycList}
          onClick={() => {
            navigate(`/content/company/kyclist/main`);
          }}
        >
          <b className={styles.returnToKyc}>Return to KYC List</b>
        </button>
      </div>
    </div>
  );
};

OfflineSubmission.propTypes = {
  className: PropTypes.string,
};

export default OfflineSubmission;

// ApprovedPOPup
