import React, { useEffect } from "react";
import Contractview from "./AdminView/Contractview";
import styles from "./ContractList.module.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import ContractMain from "./ContractMain/ContractMain";
import NewContract from "./NewContract/NewContract";
import ContractDetails from "./ContractDetails/ContractDetails";
export default function ContractList() {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/content/contract") {
      navigate("main");
    }
  }, []);
  return (
    // <div className={styles.contract}>
    //  <Contractview/>
    // </div>
    <Routes>
      <Route path="main" element={<ContractMain />} />
      <Route path="newcontract" element={<NewContract />} />
      <Route path="contractdetail" element={<ContractDetails />} />
      <Route path="viewcontract" element={<Contractview />} />
    </Routes>
  );
}
