import PropTypes from "prop-types";
import styles from "./ContractMain.module.css";
import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import config from "../../../../service/config";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useNavigate } from "react-router-dom";
import companyStore from "../../CompanyStore";
import { Pagination } from "antd";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
const ContractMain = ({ className = "" }) => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [showModel, setShowModel] = useState(false);
  const [currentcompid, setCurrentCompId] = useState("");
  const [createdDateFilter, setCreatedDateFilter] = useState(null);
  const [textFilter, setTextFilter] = useState("");
  const [currentCompName, setCurrentCompName] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [statusFilter, setStatusFilter] = useState("");
  console.log("admin", companyStore.AdminEmail);

  useEffect(() => {
    let obj = {
      escrow_id: companyStore.escrowId,
      escrow_name: companyStore.escrowName,
      admin: {
        email: companyStore.AdminEmail,
      },
    };
    axios
      .post(`${config.mock_api_endpoint}/api/contractList`, obj)
      .then((res) => {
        const sortedData = res.data.contracts.sort((a, b) => {
          const dateA = dayjs(a.createdAt); // ISO format is parsed automatically
          const dateB = dayjs(b.createdAt);
          return dateB - dateA; // Sort in descending order
        });
        setData(sortedData);
        setFilteredData(sortedData);
        console.log(res.data);
      })
      .catch((err) => {
        console.log("%o", err);
      });
  }, [showModel]);
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const resetFilters = () => {
    setFilteredData(data);
  };

  const applyFilters = () => {
    let filtered = [...data];

    if (statusFilter) {
      filtered = filtered.filter((item) => item.status === statusFilter);
    }

    if (createdDateFilter) {
      filtered = filtered.filter((item) => {
        console.log("created_at", item);

        const [datePart] = item.createdAt?.split(" / "); // Extract the date part before '/'
        const itemDate = new Date(datePart).toDateString(); // Convert to a comparable date format
        const filterDate = new Date(createdDateFilter).toDateString();

        console.log("dateFilter--->", itemDate, filterDate);

        return itemDate === filterDate;
      });
    }

    setFilteredData(filtered);
    setCurrentPage(1);
  };

  const handleTextFilterChange = (e) => {
    const value = e.target.value;
    setTextFilter(value);

    let filtered = data;

    if (value) {
      filtered = filtered.filter(
        (item) =>
          item.createdAt.toLowerCase().includes(value.toLowerCase()) ||
          item.contract_name.toLowerCase().includes(value.toLowerCase()) ||
          item.status.toLowerCase().includes(value.toLowerCase()),
      );
    }

    setFilteredData(filtered);
    setCurrentPage(1); // Reset to the first page
  };

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const currentPageData = filteredData?.slice(startIndex, startIndex + pageSize);

  return (
    <div className={styles.pageContainer}>
      <div className={styles.newCompany_div}>
        <button
          onClick={() => {
            navigate("/content/contract/newcontract");
          }}
          className={styles.newCompany}
        >
          <img className={styles.plusCircleIcon} alt="" src="/pluscircle.svg" />
          <b className={styles.addNewCompany}>Add Contract</b>
        </button>
      </div>

      <div className={styles.searchFiltersParent}>
        <div className={styles.frameParent}>
          {/* MUI search for table */}
          <div className={styles.leadingIconParent}>
            <img className={styles.leadingIcon} alt="" src="/leadingicon.svg" />
            <TextField label="Search" variant="standard" value={textFilter} onChange={handleTextFilterChange} />
          </div>

          <div className={styles.mobileMenuParent}>
            {/* mui date and time */}
            {/* <div className={styles.mobileMenu}>
              <div className={styles.field}>
                <div className={styles.content}>
                  <div className={styles.floatingLabel}>
                    <div className={styles.label}>{`Created date `}</div>
                  </div>
                  <div className={styles.input}>
                    <div className={styles.input1}>28/08/2024</div>
                    <div className={styles.input1}>12:59AM</div>
                    <img
                      className={styles.cursorIcon}
                      alt=""
                      src="/cursor.svg"
                    />
                  </div>
                </div>
              </div>
              <img
                className={styles.mobileMenuChild}
                alt=""
                src="/trailingicon-2.svg"
              />
            </div> */}
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label="Created Date"
                value={createdDateFilter}
                onChange={(newValue) => setCreatedDateFilter(newValue)}
                renderInput={(params) => <TextField {...params} />}
                sx={{ "& .MuiInputBase-root": { width: "250px", borderRadius: "12px" } }}
              />
            </LocalizationProvider> */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Created Date"
                value={createdDateFilter}
                onChange={(newValue) => setCreatedDateFilter(newValue)}
                renderInput={(params) => <TextField {...params} />}
                sx={{
                  "& .MuiInputBase-root": {
                    width: "250px",
                    borderRadius: "12px",
                  },
                }}
              />
            </LocalizationProvider>

            <FormControl sx={{ width: "250px" }}>
              <InputLabel id="escrow-select-label">Status</InputLabel>
              <Select
                labelId="escrow-select-label"
                id="escrow-select"
                value={statusFilter}
                label="Status"
                onChange={(e) => setStatusFilter(e.target.value)}
                sx={{ borderRadius: "12px" }}
              >
                <MenuItem value={"sent"}>Sent</MenuItem>
                <MenuItem value={"Changed"}>Changed</MenuItem>
                {/* <MenuItem value={"Completed"}>Completed</MenuItem> */}
                <MenuItem value={"Accepted"}>Accepted</MenuItem>
              </Select>
            </FormControl>
            <div className={styles.loadsButtons1} onClick={applyFilters}>
              <img className={styles.boxArrowInDownIcon} alt="" src="/boxarrowindown1.svg" />
              <b className={styles.addNewCompany}>Apply</b>
            </div>
          </div>

          {/* Frame Container ends */}
        </div>
      </div>

      <div className={styles.frameGroup}>
        <table className={styles.table}>
          <thead className={styles.theadcontain}>
            <tr className={styles.trheaderContent}>
              <th>Created Date</th>
              <th>Contract Name</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className={styles.tbodycontain}>
            {currentPageData && currentPageData.length > 0 ? (
              currentPageData.map((detail) => (
                <tr key={detail.contract_id}>
                  <td>{dayjs(detail.createdAt).format("DD MMM YYYY / hh:mm a")}</td>
                  <td>{detail.contract_name}</td>
                  <td>{detail.status.toUpperCase()}</td>
                  <td>
                    <button
                      className={styles.change}
                      onClick={() => {
                        handleScrollToTop();
                        companyStore.setContractID(detail.contract_id);
                        console.log(detail._id);
                        navigate("/content/contract/contractdetail");
                      }}
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className={styles.noRecords}>
                  No Records Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-end p-5">
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={filteredData?.length}
          onChange={handlePaginationChange}
          // showSizeChanger
        />
      </div>
    </div>
  );
};

ContractMain.propTypes = {
  className: PropTypes.string,
};

export default ContractMain;
