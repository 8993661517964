import { useCallback } from "react";
import { TextField, InputAdornment, Icon, IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./CompanyKYC.module.css";
import { useEffect, useState } from "react";
import axios from "axios";
import Lottie from "lottie-react";
import load from "./LoadGIF.json";
import config from "../../../../service/config";
import companyStore from "../../CompanyStore";
import OfflineKyc from "./OfflineCompanyKyc/OfflineKyc";

const CompanyKYC = ({ className = "" }) => {
  const [compName, setcompName] = useState("");
  const [POCname, setPOCName] = useState("");
  const [email, setEmail] = useState("");
  const [data, setData] = useState("");
  const [remark, setRemark] = useState("");
  const [id, setID] = useState("");
  const [Name, setName] = useState("");
  const [escrowName, setEscrowName] = useState("");
  const [len, setLen] = useState();
  const [clicked, setClicked] = useState(false);
  const [mode, setMode] = useState("external");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    setID(companyStore.companyID);
    setName(companyStore.companyName);
    setEscrowName(companyStore.escrowName);
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleClick = () => {
    // if (email == "" || POCname == "") {
    //   alert("All Fields Are Required");
    //   return;
    // }
    const validationErrors = {};

    if (!POCname.trim()) validationErrors.POCname = "Authorized Signatory is required.";
    if (!email.trim()) validationErrors.email = "Email ID is required.";
    else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) validationErrors.email = "Invalid email format.";
    // if (!request && !mode) validationErrors.request = "Request type is required.";

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setErrors({});
    setClicked(true);
    const obj = {
      company_id: id,
      company_name: Name,
      authorized_signatory_email: email,
      authorized_signatory: POCname,
      remark: remark,
      mode: "online",
      // request: request,
    };

    axios
      .post(`${config.node_api_endpoint}/v2/company/generate_kyb_request/form`, obj)
      .then(() => {
        console.log("Data has been Send");
        handleScrollToTop();
        navigate(`/content/company/kyc_request`);
        setcompName("");
        setEmail("");
        setPOCName("");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const clearAll = () => {
    setcompName("");
    setEmail("");
    setPOCName("");
  };
  const handlenavigate = () => {
    navigate(`/content/company/kyclist/main`);
  };

  const handleMode = () => {
    setMode(!mode);
  };
  // const handleRequest = (e) => {
  //   setRequest(e.target.value);
  // };

  // if(!isVisibleComp) return null;

  return (
    <div className={[styles.pageContainer, className].join(" ")}>
      <div className={styles.frameParent}>
        <button
          className={styles.arrowNarrowLeftParent}
          // onClick={}
          onClick={handlenavigate}
        >
          <img className={styles.arrowNarrowLeftIcon} alt="" src="/arrownarrowleft1.svg" />
          <div className={styles.back}>Back</div>
        </button>
        <b className={`${styles.escrowName} mt-4`}>{escrowName}</b>
        <b className={`${styles.compName} mt-2 mb-4`}>{Name}</b>
        <div className="d-flex align-items-center w-100 mb-4">
          <div>
            <label className="d-flex align-items-center pe-4">
              Send To <span style={{ color: "red" }}>*</span>
            </label>
          </div>
          <div className="pe-3">
            <input
              type="radio"
              id="external"
              name="mode"
              value="external"
              checked={mode === "external"}
              onChange={(e) => setMode(e.target.value)}
            />
            <label className="p-2" htmlFor="external">
              Customer
            </label>
          </div>
          <div>
            <input
              type="radio"
              id="internal"
              name="mode"
              value="internal"
              checked={mode === "internal"}
              onChange={(e) => setMode(e.target.value)}
            />
            <label className="p-2" htmlFor="internal">
              Internal Team
            </label>
          </div>
        </div>
        {/* {!mode && (
          <div className="d-flex justify-content-around w-100">
            <div>
              <label className="form-label">
                Request <span style={{ color: "red" }}>*</span>
              </label>
            </div>
            <div className="mb-4 w-50">
              <select
                name="requestTo"
                className="form-select"
                // value={""}
                onChange={handleRequest}
              >
                <option value="">Select an option</option>
                <option value="internal">to Internal</option>
                <option value="external">to External</option>
              </select>
              {errors.request && <p className="text-danger">{errors.request}</p>}
            </div>
          </div>
        )} */}
        {mode === "external" ? (
          <>
            <div className={styles.frameGroup}>
              <div className={`${styles.companyKycParent} mb-5`}>
                <div className={styles.companyKyc}>Company KYC</div>
                <button className={styles.input} onClick={clearAll}>
                  Clear
                </button>
              </div>
              <div className={styles.mobileMenuParent}>
                <TextField
                  className={styles.mobileMenu}
                  color="primary"
                  label="Company Name"
                  variant="outlined"
                  value={Name}
                  disabled={true}
                  sx={{ "& .MuiInputBase-root": { height: "56px", color: "black !important" } }}
                />

                <div className={styles.mobileMenuGroup}>
                  <TextField
                    className={styles.mobileMenu1}
                    color="primary"
                    label="Authorized Signatory"
                    variant="outlined"
                    value={POCname}
                    onChange={(e) => {
                      setPOCName(e.target.value);
                    }}
                    sx={{ "& .MuiInputBase-root": { height: "56px" } }}
                    error={!!errors.POCname}
                    helperText={errors.POCname}
                  />
                  <div className={styles.mobileMenu1}>
                    <TextField
                      className={styles.field}
                      color="primary"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      label="Email ID"
                      variant="outlined"
                      type="email"
                      sx={{
                        "& .MuiInputBase-root": { height: "56px" },
                        width: "344px",
                      }}
                      error={!!errors.email}
                      helperText={errors.email}
                    />
                  </div>
                </div>

                <div className={styles.mobileMenu3}>
                  <textarea
                    className={styles.field1}
                    placeholder="Remark"
                    type="text"
                    value={remark}
                    onChange={(e) => {
                      setRemark(e.target.value);
                    }}
                  />
                </div>
              </div>
              <button
                className={`${styles.loadsButtons} w-100 d-flex align-items-center justify-content-center`}
                onClick={() => {
                  handleClick();
                }}
              >
                {clicked && (
                  <Lottie className={styles.httpslottiefilescomanimatIcon} animationData={load} loop={true} />
                )}

                <b className={`${styles.generateRequest}`}>Generate Request</b>
              </button>
            </div>
          </>
        ) : (
          <div className={`${styles.frameGroupOffline} mb-5`}>
            <OfflineKyc />
          </div>
        )}
      </div>
    </div>
  );
};

CompanyKYC.propTypes = {
  className: PropTypes.string,
};

export default CompanyKYC;
