import React, { useState } from "react";
import styles from "./NewCompanyModel.module.css";
import axios from "axios";
import config from "../../../../service/config";

export default function NewCompanyModel({ className = "", isVisible, onclose }) {
  const [compName, setCompName] = useState("");

  const HandleData = () => {
    const trimmedCompName = compName.trim();

    if (trimmedCompName === "") {
      alert("Escrow Name is Required");
      return;
    }

    const payload = { name: trimmedCompName };

    axios
      .post(`${config.node_api_endpoint}/v1/escrow`, payload)
      .then(() => {
        console.log("Company Added");
        setCompName("");
        onclose();
      })
      .catch(() => {
        alert("Could Not Register. Try Again.");
        setCompName("");
        onclose();
      });
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className={styles.newModelcontainer}>
      <div className={[styles.addCompany, className].join(" ")}>
        <div className={styles.newCompany}>New Escrow account</div>
        <div className={styles.mobileMenuParent}>
          <div className={styles.mobileMenu}>
            <input
              placeholder="Escrow Name"
              value={compName}
              className={styles.field}
              onChange={(e) => {
                setCompName(e.target.value);
              }}
            />

            <img className={styles.trailingIcon2} alt="" src="/trailingicon-21.svg" />
          </div>
          <div className={styles.loadsButtonsParent}>
            <div
              className={styles.loadsButtons}
              onClick={() => {
                setCompName("");
                onclose();
              }}
            >
              <img className={styles.boxArrowInDownIcon} alt="" src="/boxarrowindown3.svg" />
              <b className={styles.loads}>Cancel</b>
            </div>
            <div
              className={styles.loadsButtons1}
              onClick={() => {
                HandleData();
              }}
            >
              <img className={styles.boxArrowInDownIcon} alt="" src="/boxarrowindown3.svg" />
              <b className={styles.loads}>Create</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
