import PropTypes from "prop-types";
import styles from "./EscrowContainer.module.css";
import { Fragment, useEffect, useState } from "react";
import Axios from "axios";
import NewCompanyModel from "../KYCList/AddEscrow/NewCompanyModel";
import SelectActivity1 from "../SelectActivity/SelectActivity1";
import config from "../../../service/config";
import { TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Spin } from "antd";
import { Pagination } from "antd";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const PageContainer = ({ className = "" }) => {
  const [data, setData] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [showActivity, setShowActivity] = useState(false);
  const [currentcompid, setCurrentCompId] = useState("");
  const [createdDateFilter, setCreatedDateFilter] = useState(null);
  const [textFilter, setTextFilter] = useState("");
  const [currentCompName, setCurrentCompName] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  useEffect(() => {
    setLoader(true);
    Axios.get(`${config.node_api_endpoint}/v1/escrow`)
      .then((res) => {
        setData(res.data);
        console.log("res-->", res.data);
        const sortedData = res.data.sort((a, b) => {
          const dateA = dayjs(a.created_at, "DD MMM YYYY / hh:mm a");
          const dateB = dayjs(b.created_at, "DD MMM YYYY / hh:mm a");
          return dateB - dateA;
        });
        console.log("sortedData", sortedData);

        setFilteredData(sortedData);
        setLoader(false);
      })
      .catch((er) => {
        console.log(er);
      });
  }, [showModel]);
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleTextFilterChange = (e) => {
    const value = e.target.value;
    setTextFilter(value);

    let filtered = data;

    if (value) {
      filtered = filtered.filter(
        (item) =>
          item.created_at.toLowerCase().includes(value.toLowerCase()) ||
          item.name.toLowerCase().includes(value.toLowerCase()),
      );
    }

    setFilteredData(filtered);
    setCurrentPage(1); // Reset to the first page
  };
  const applyFilters = () => {
    let filtered = [...data];

    if (createdDateFilter) {
      filtered = filtered.filter((item) => {
        const [datePart] = item.created_at.split(" / "); // Extract the date part before '/'
        const itemDate = new Date(datePart).toDateString(); // Convert to a comparable date format
        const filterDate = new Date(createdDateFilter).toDateString();

        console.log("dateFilter--->", itemDate, filterDate);

        return itemDate === filterDate;
      });
    }

    setFilteredData(filtered);
    setCurrentPage(1);
  };

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  // Calculate current page data
  const startIndex = (currentPage - 1) * pageSize;
  const currentPageData = filteredData.slice(startIndex, startIndex + pageSize);

  return (
    <div className={`${[styles.pageContainer, className].join(" ")} h-100`}>
      <Spin
        size="large"
        tip="Loading..."
        spinning={loader}
        // className="d-flex justify-content-center align-items-center h-100"
        wrapperClassName="spin-nested-loading"
      >
        <div className={styles.newCompany_div}>
          <button
            onClick={() => {
              setShowModel(true);
            }}
            className={styles.newCompany}
          >
            <img className={styles.plusCircleIcon} alt="" src="/pluscircle.svg" />
            <b className={styles.addNewCompany}>Add Escrow account</b>
          </button>
        </div>

        <div className={`${styles.searchFiltersParent} mt-3 p-2`}>
          <div className={styles.frameParent}>
            {/* MUI search for table */}
            <div className={styles.leadingIconParent}>
              <img className={styles.leadingIcon} alt="" src="/leadingicon.svg" />
              <TextField label="Search" variant="standard" value={textFilter} onChange={handleTextFilterChange} />
            </div>

            <div className={styles.mobileMenuParent}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Created Date"
                  value={createdDateFilter}
                  onChange={(newValue) => setCreatedDateFilter(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{
                    "& .MuiInputBase-root": {
                      borderRadius: "12px",
                    },
                  }}
                />
              </LocalizationProvider>

              {/* mui filter for table */}
              {/* <div className={styles.mobileMenu}>
              <div className={styles.field}>
                <div className={styles.content}>
                  <div className={styles.floatingLabel}>
                    <div className={styles.label}>Status</div>
                  </div>
                  <div className={styles.input3}>
                    <div className={styles.input1}>Requested</div>
                    <img
                      className={styles.cursorIcon}
                      alt=""
                      src="/boxarrowindown3.svg"
                    />
                  </div>
                </div>
              </div>
              <img className={styles.mobileMenuChild} alt="hey" src="/arrow14.svg" />
            </div> */}

              {/* button to apply filter to table */}
              <div className={styles.loadsButtons1} onClick={applyFilters}>
                <img className={styles.boxArrowInDownIcon} alt="" src="/boxarrowindown1.svg" />
                <b className={styles.addNewCompany}>Apply</b>
              </div>
            </div>

            {/* Frame Container ends */}
          </div>
        </div>

        <div className={styles.frameGroup}>
          <table className={styles.table}>
            <thead className={styles.theadcontain}>
              <tr className={styles.trheaderContent}>
                <th>Created Date</th>
                <th>Escrow Name</th>
                {/* <th>Status</th> */}
                <th>Action</th>
              </tr>
            </thead>
            <tbody className={styles.tbodycontain}>
              {currentPageData && currentPageData.length > 0 ? (
                currentPageData.map((detail) => {
                  return (
                    <tr key={detail._id}>
                      <td>{detail.created_at}</td>
                      <td>{detail.name}</td>
                      {/* <td>{detail.status}</td> */}
                      <td>
                        <button
                          className={styles.change}
                          onClick={() => {
                            handleScrollToTop();
                            setShowActivity(true);
                            setCurrentCompId(detail._id);
                            setCurrentCompName(detail.name);
                            console.log(detail._id);
                          }}
                        >
                          View More
                        </button>
                      </td>
                      {/* `company/${detail.id}` */}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="4" className={styles.noRecords}>
                    No Records Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-end container mt-4 mb-2">
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={filteredData.length}
            onChange={handlePaginationChange}
            // showSizeChanger
          />
        </div>

        <SelectActivity1
          isVisible={showActivity}
          onclose={() => {
            setShowActivity(false);
          }}
          escrowId={currentcompid}
          escrowName={currentCompName}
        />
        <NewCompanyModel
          isVisible={showModel}
          onclose={() => {
            setShowModel(false);
          }}
        />
      </Spin>
    </div>
  );
};

PageContainer.propTypes = {
  className: PropTypes.string,
};

export default PageContainer;
