import PropTypes from "prop-types";
import styles from "./EsignMain.module.css";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import config from "../../../service/config";
import DeleteEsignRequest from "./DeleteEsignRequest/DeleteEsignRequest";
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import companyStore from "../CompanyStore";
import { Pagination } from "antd";
import { DatePicker } from "@mui/x-date-pickers";

const EsignMain = ({ className = "" }) => {
  const [data, setData] = useState([]);
  const [arr, setArr] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [textFilter, setTextFilter] = useState("");
  const [showModel, setShowModel] = useState(false);
  const [compName, setCompName] = useState("");
  const [escrowName, setEscrowName] = useState("");
  const [createdDateFilter, setCreatedDateFilter] = useState(null);
  const [docId, setdocId] = useState("");
  const [id, setID] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  useEffect(() => {
    companyStore.setContractID("");
    console.log("esign main");
    setID(companyStore.companyID);
    console.log("company id=", id);
    setCompName(companyStore.escrowName);
    setEscrowName(companyStore.escrowName);
    let payload = {
      escrow_id: companyStore.escrowId,
    };

    axios
      .post(`${config.node_api_endpoint}/v2/esign/listdocuments`, payload)
      .then((res) => {
        setData(res.data);
        setFilteredData(res.data.reverse());
        console.log("esign doc id List Document", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const navigate = useNavigate();

  const resetFilters = () => {
    setFilteredData(data);
  };

  const applyFilters = () => {
    let filtered = [...data];

    if (textFilter) {
      filtered = filtered.filter((item) => item.agreement_name.toLowerCase().includes(textFilter.toLowerCase()));
    }

    if (statusFilter) {
      filtered = filtered.filter((item) => item.status === statusFilter);
    }

    // if (createdDateFilter) {
    //   filtered = filtered.filter(
    //     (item) => new Date(item.created_at).toDateString() === new Date(createdDateFilter).toDateString(),
    //   );
    // }
    if (createdDateFilter) {
      filtered = filtered.filter((item) => {
        const [datePart] = item.created_at.split(" / "); // Extract the date part before '/'
        const itemDate = new Date(datePart).toDateString(); // Convert to a comparable date format
        const filterDate = new Date(createdDateFilter).toDateString();

        console.log("dateFilter--->", itemDate, filterDate);

        return itemDate === filterDate;
      });
    }

    setFilteredData(filtered); // Update the filtered data state
  };

  const handleTextFilterChange = (e) => {
    const value = e.target.value;
    setTextFilter(value);

    let filtered = data;

    if (value) {
      filtered = filtered.filter(
        (item) =>
          item.agreement_name.toLowerCase().includes(value.toLowerCase()) ||
          item.created_at.toLowerCase().includes(value.toLowerCase()) ||
          item?.status?.toLowerCase().includes(value.toLowerCase()),
      );
    }

    setFilteredData(filtered);
  };

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const currentPageData = filteredData.slice(startIndex, startIndex + pageSize);

  return (
    <Fragment>
      <div className={styles.pageContainer}>
        <div className={styles.frameParent}>
          <div className={styles.escrowstackPrivateLimitedParent}>
            <b className={`${styles.escrowName} mt-4`}>{escrowName}</b>

            <button
              className={styles.loadsButtons}
              onClick={() => {
                navigate(`/content/esign/createesign`);
              }}
            >
              <img className={styles.plusCircleIcon} alt="" src="/pluscircle2.svg" />
              <b className={styles.newKycRequest}>New E-sign Request</b>
            </button>
          </div>
          <b className={`${styles.compName} mt-2 mb-4`}>{compName}</b>
          <div className={styles.frameWrapper}>
            <div className={styles.frameGroup}>
              {/* Filter inputs */}
              <div className={styles.frameContainer}>
                <div className={styles.frameDiv}>
                  <img className={styles.leadingIcon} alt="" src="/leadingicon3.svg" />
                  <TextField label="Search" variant="standard" value={textFilter} onChange={handleTextFilterChange} />
                </div>
              </div>

              <div className={`${styles.mobileMenuParent}`}>
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="Created Date"
                    value={createdDateFilter}
                    onChange={(newValue) => setCreatedDateFilter(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                    sx={{ "& .MuiInputBase-root": { width: "250px", borderRadius: "12px" } }}
                  />
                </LocalizationProvider> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Created Date"
                    value={createdDateFilter}
                    onChange={(newValue) => setCreatedDateFilter(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                    sx={{
                      "& .MuiInputBase-root": {
                        width: "250px",
                        borderRadius: "12px",
                      },
                    }}
                  />
                </LocalizationProvider>

                <FormControl sx={{ width: "250px" }}>
                  <InputLabel id="escrow-select-label">Status</InputLabel>
                  <Select
                    labelId="escrow-select-label"
                    id="escrow-select"
                    value={statusFilter}
                    label="Status"
                    onChange={(e) => setStatusFilter(e.target.value)}
                    sx={{ borderRadius: "12px" }}
                  >
                    <MenuItem value={"draft"}>Draft</MenuItem>
                    <MenuItem value={"requested"}>Requested</MenuItem>
                    <MenuItem value={"Completed"}>Completed</MenuItem>
                    <MenuItem value={"Pending"}>Pending</MenuItem>
                    <MenuItem value={"expired"}>Expired</MenuItem>
                  </Select>
                </FormControl>

                {/* Button to apply filter */}
                <div className={styles.loadsButtons1} onClick={applyFilters}>
                  <b className={styles.escrowstackPrivateLimited}>Apply</b>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <TableContainer component={Paper} className={styles.table_div}>
              <Table>
                <thead
                  className={styles.theadcontain}
                  style={{
                    borderBottom: "1px solid #7575757F",
                    padding: "16px 60px 16px 32px",
                    height: "20px",
                    lineHeight: "35px",
                    overflow: "hidden",
                  }}
                >
                  <tr>
                    <th colSpan={6}>
                      <span
                        style={{ fontSize: "24px", padding: "5px", fontWeight: "600", margin: "5px", color: "#4c4e64" }}
                      >
                        E-sign & E-stamp List
                      </span>
                    </th>
                  </tr>
                </thead>
                <TableHead className={styles.theadcontain}>
                  <TableRow className={styles.trheaderContent}>
                    <TableCell>Name Of Aggrement</TableCell>
                    <TableCell>Last Updated</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={styles.tbodycontain}>
                  {currentPageData && currentPageData?.length > 0 ? (
                    currentPageData?.map((detail, index) => (
                      <TableRow key={index}>
                        <TableCell>{detail.agreement_name}</TableCell>
                        <TableCell>{detail.created_at.split("T")[0]}</TableCell>
                        <TableCell>{detail.status?.toUpperCase() || ""}</TableCell>
                        <TableCell className={styles.containerbtn}>
                          <button
                            className={styles.btn} /*view Request Button*/
                            onClick={() => {
                              setdocId(detail._id);
                              companyStore.setDoc_id(detail._id);
                              navigate(`/content/esign/preview/doc`);
                            }}
                            //[0] is docid, [1] is company id
                          >
                            <img className={styles.eyeIcon} alt="" src="/eye.svg" />
                          </button>
                          <b className={styles.b}>/</b>
                          <button
                            className={styles.btn} /*Delete Request Button*/
                            onClick={() => {
                              setShowModel(true);
                              setdocId(detail._id);
                              console.log("docid", detail._id);
                              companyStore.setDoc_id(detail._id);
                            }}
                          >
                            <img className={styles.eyeIcon} alt="" src="/trash-01.svg" />
                          </button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className={styles.noRecords}>
                        No Records Found
                      </td>
                    </tr>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="d-flex justify-content-end p-5">
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={filteredData.length}
              onChange={handlePaginationChange}
              // showSizeChanger
            />
          </div>
        </div>
      </div>
      <DeleteEsignRequest
        isVisible={showModel}
        onclose={() => {
          setShowModel(false);
          window.location.reload();
        }}
        Docid={docId}
      />
    </Fragment>
  );
};

EsignMain.propTypes = {
  className: PropTypes.string,
};

export default EsignMain;
