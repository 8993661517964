import React, { useState } from "react";
import styles from "./NewCompanyModel.module.css";
import axios from "axios";
import config from "../../../../../service/config";
import companyStore from "../../../CompanyStore";

export default function NewCompanyModel({ className = "", isVisible, onclose, setShowModel }) {
  const [compName, setCompName] = useState("");
  const HandleData = () => {
    if (compName.trim() === "") {
      alert("Company Name is Required");
      return;
    }

    const payload = {
      escrow_id: companyStore.escrowId,
      company_name: compName,
    };

    axios
      .post(`${config.node_api_endpoint}/v2/company/create`, payload)
      .then(() => {
        console.log("Company Added");
        setCompName("");
        onclose();
      })
      .catch(() => {
        alert("Could Not Register Try Again ");
        setCompName("");
        onclose();
      });
  };
  if (!isVisible) {
    return null;
  }
  return (
    <div className={styles.newModelcontainer}>
      <div className={[styles.addCompany, className].join(" ")}>
        {/* <div className={styles.newCompany}>Create New Company</div> */}
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <span style={{ fontWeight: "500" }}>Create New Company</span>
          <img
            className={styles.xCircleIcon}
            alt=""
            src="/xcircle.svg"
            onClick={() => {
              // onClose();
              setShowModel(false);
            }}
          />
        </div>

        <div className={styles.mobileMenuParent}>
          <div className={styles.mobileMenu}>
            <input
              placeholder="Company Name"
              value={compName}
              className={styles.field}
              onChange={(e) => {
                setCompName(e.target.value);
              }}
            />

            <img className={styles.trailingIcon2} alt="" src="/trailingicon-21.svg" />
          </div>
          <div className={styles.loadsButtonsParent}>
            <div
              className={styles.loadsButtons}
              onClick={() => {
                setCompName("");
                onclose();
              }}
            >
              <img className={styles.boxArrowInDownIcon} alt="" src="/boxarrowindown3.svg" />
              <b className={styles.loads}>Cancel</b>
            </div>
            <div
              className={styles.loadsButtons1}
              onClick={() => {
                HandleData();
              }}
            >
              <img className={styles.boxArrowInDownIcon} alt="" src="/boxarrowindown3.svg" />
              <b className={styles.loads}>Create</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
