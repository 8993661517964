import React, { useState, useEffect, useRef } from "react";
import Draggable from "react-draggable";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import config from "../../../../service/config";
import styles from "./SignatureBox.module.css";
import companyStore from "../../CompanyStore";
import { useAllProduct } from "../../../api-context/commonApiContext";
const Sign = ({ button, reason }) => {
  const { setESignCoordinates } = useAllProduct();

  const [items, setItems] = useState([]);
  const [imageData, setImageData] = useState({});
  const [img, setimg] = useState([]);
  const [draggables, setDraggables] = useState([]);
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const containerRefs = useRef({});
  const [imageSizes, setImageSizes] = useState({});
  const navigate = useNavigate();

  function digito2EsctackCordinates(dlx, dly, dux, duy) {
    var c1 = 1.423636485;
    var c2 = -7.422450698;
    var c3 = -1.982936128;
    var c4 = 24.98120326;

    var eslx = dlx * c1 + dly * c3;
    var esly = dlx * c2 + dly * c4;
    var esux = dux * c1 + duy * c3;
    var esuy = dux * c2 + duy * c4;
    return {
      lx: eslx,
      ly: esly,
      ux: esux,
      uy: esuy,
    };
  }

  const coordinates = [
    { x: 290, y: 475 },
    { x: 180, y: 540 },
    { x: 60, y: 540 },
    { x: 300, y: 513 },
    { x: 180, y: 513 },
    { x: 60, y: 513 },
  ];

  useEffect(() => {
    setId(companyStore.companyID);
    const doc_id = companyStore.doc_id;
    console.log("previewing documents");
    axios.post(`${config.node_api_endpoint}/v1/esign/getdocument/`, { doc_id }).then((res) => {
      // console.log(res.data);
      setData(res.data.signing_parties);
      setImageData(res.data.png_url);
      console.log("result", res.data);

      setimg(res.data.png_url);
      var draggableArray = [];
      const esignData = res.data.signing_parties;
      setItems(esignData);
      for (var i = 0; i < esignData.length; i++) {
        var esignDataItem = esignData[i];
        var line_no = Math.floor(i / 4);
        // console.log('%o',JSON.stringify(kycDataItem))
        // console.log("coordinates X",kycDataItem.name,(coordinates[i].lx+coordinates[i].ux)/6);
        // console.log("coordinates Y",kycDataItem.name,(coordinates[i].ly+coordinates[i].uy)/6);

        draggableArray.push({
          name: esignDataItem.name,
          email: esignDataItem.email,
          x: coordinates[0].x - (i % 4) * 100,
          y: coordinates[0].y - line_no * 27,
          imageId: esignDataItem.imageId,
        });
      }
      setDraggables(draggableArray);
    });
  }, []);

  const handleImageLoad = (e, imageId) => {
    const imgElement = e.target;
    setImageSizes((prev) => ({
      ...prev,
      [imageId]: {
        width: imgElement.naturalWidth / 4,
        height: imgElement.naturalHeight / 4,
      },
    }));
  };

  function esStackCordinatesToDigio(x, y) {
    //COnverting escrow Coordinates to Digiio Coordinates
    console.log("in function");

    var elx = x;
    var ely = 1;
    var eux = y; //because tranformation matrix was derived with y in eux position by mistake
    var euy = 1;
    var c1 = 0.583297106247098;
    var c2 = 0.166639121;
    var c3 = 274.3495435;
    var c4 = -39.985987035;

    var dlx = Math.floor(449.338661360347 - (300 - x) * 1.41666974191992);
    var dly = Math.floor(10.0057451431493 - (540 - y) * -1.1854809);
    var dux = Math.floor(589.329955680173 - (449.338661360347 - dlx));
    var duy = Math.floor(49.9991308793456 - (10.0057451431493 - dly));
    return {
      llx: dlx,
      lly: dly,
      urx: dux,
      ury: duy,
    };
  }
  let eCoordinates = [];
  const conversion = (x, y, pg, user) => {
    console.log("coordi--->", x, y);

    eCoordinates.push({
      PageNo: pg,
      ControlID: 4,
      AssignedTo: user,
      Top: (785 - Math.floor(y * 1.586) > 785 ? 770 : 785 - Math.floor(y * 1.586)) - 10, //ul
      Left: Math.floor(x * 1.586) > 440 ? 440 : Math.floor(x * 1.586), //ul
      Width: Math.floor(x * 1.586) + 90 > 600 ? 600 : Math.floor(x * 1.586) + 90,
      // Height: 695-( Math.floor(y * 1.586) > 776 ? 770 : Math.floor(y * 1.586))-45,//lr
      Height: 785 - (Math.floor(y * 1.586) - 60) > 785 ? 785 : 785 - (Math.floor(y * 1.586) - 60), //lr
    });
    // eCoordinates.push({
    //   PageNo: pg,
    //   ControlID: 4,
    //   AssignedTo: user,
    //   Top:429-( Math.floor(y * 1.586) > 776 ? 770 : Math.floor(y * 1.586)),//ul
    //   Left:792- Math.floor(x * 1.586) > 466 ? 460 : Math.floor(x * 1.586),//ul
    //   Width: Math.floor(x * 1.586) > 466 ? 460 : Math.floor(x * 1.586)+145,
    //   // Height: 695-( Math.floor(y * 1.586) > 776 ? 770 : Math.floor(y * 1.586))-45,//lr
    //   Height: 792-( Math.floor(y * 1.586) > 776 ? 770 : Math.floor(y * 1.586))-45,//lr
    // });
  };
  const handleSendClick = () => {
    console.log("in it");
    console.log("handle send click");
    console.log(draggables);

    // Initialize an empty object to hold the transformed data
    const signCoordinates = {};
    let imagelength = Object.keys(imageData).length;
    // let coordinate = esStackCordinatesToDigio(item.x, item.y);
    // Process each draggable item
    draggables.forEach((item, ind) => {
      console.log("item", item);
      signCoordinates[item.email] = {};
      img.map((value, index) => {
        signCoordinates[item.email][index + 1] = [conversion(item.x, item.y, index + 1, ind + 1)];
      });
    });

    // Log the final result
    console.log("updated data", { eCoordinates: eCoordinates });

    if (reason === "E-SignWithE-Stamp") {
      setESignCoordinates(eCoordinates);
      navigate(`/content/esign/esignwithestamp/${companyStore.doc_id}`);
    } else {
      let obj = {
        doc_id: companyStore.doc_id,
        sign_coordinates: eCoordinates,
        SignatureSettings: {
          ModeOfAuthentication: 0,
          ModeOfSignature: "12",
        },
        reason: reason,
      };
      axios
        .post(`${config.node_api_endpoint}/v1/esign/sign_document`, obj)
        .then((res) => {
          console.log("Coordinates has been send");
          navigate(`/content/esign/esign-requested`);
        })
        .catch((err) => {
          console.log(err);
          alert(err);
        });
    }
  };

  useEffect(() => {
    console.log("in effect");
    if (button) {
      handleSendClick();
    }
  }, [button]);

  const handleButtonClick = (item) => {
    // to Add new Draggable div of the same user
    // setDraggables([...draggables, { id: item.id, name: item.name, email: item.email, x: 0, y: 0, imageId: item.imageId }]);
    console.log([
      ...draggables,
      { id: item.id, name: item.name, email: item.email, x: 0, y: 0, imageId: item.imageId },
    ]);
  };

  const handleDrag = (index, e, data) => {
    setDraggables((prev) => prev.map((item, i) => (i === index ? { ...item, x: data.x, y: data.y } : item)));
  };
  const handleDragStop = (index, e, data) => {
    // setDraggables(prev => prev.map((item, i) =>
    //   i === index ? { ...item, x: data.x, y: data.y } : item
    // ));
    console.log(`Drag stopped for item at index ${index}: x=${data.x}, y=${data.y}`);
  };

  return (
    // <div style={{ display: 'flex', flexWrap: 'wrap',flexDirection:"row",width:"100%", margin:"5%" }}>

    <div className={styles.containSign}>
      {/* Send Button */}
      {/* <button onClick={handleSendClick} style={{ marginLeft: '20px', padding: '10px', backgroundColor: '#28a745', color: 'white', border: 'none', cursor: 'pointer' }}>
        Send
      </button> */}

      {Object.entries(imageData).map(([key, image]) => (
        <div
          key={key}
          ref={(el) => (containerRefs.current[image.FileId] = el)}
          className="outer-div"
          style={{
            position: "relative",
            border: "1px solid black",
            //  marginBottom:"10px",
            //  overflow: 'hidden',
            width: imageSizes[image.FileId]?.width * 1.7 || "auto",
            height: imageSizes[image.FileId]?.height * 1.7 || "auto",
          }}
        >
          {/* Image tag to retrieve the natural width and height */}
          <img
            src={image}
            alt="Background"
            onLoad={(e) => handleImageLoad(e, image.id)}
            style={{
              width: "100%",
              height: "100%",
              display: "block",
              //  border:"1px solid red",
              marginBottom: "20px",
              objectFit: "contain", // Ensures the image is fully visible without being cut
            }}
          />

          {/* Draggable elements */}
          {draggables
            .filter((d) => d.imageId === image.id)
            .map((dragItem, index) => (
              <Draggable
                key={dragItem.id}
                position={{ x: dragItem.x, y: dragItem.y }}
                onDrag={(e, data) => {
                  // console.log(data);
                  handleDrag(index, e, data);
                }}
                onStop={(e, data) => handleDragStop(index, e, data)}
                bounds="parent"
              >
                <div
                  className="inner-div"
                  style={{
                    width: "130px",
                    height: "60px",
                    backgroundColor: "#79c2a0",
                    cursor: "grab",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white",
                    borderRadius: "10px",
                    fontWeight: "bold",
                    fontFamily: "sarabun", //removable
                    position: "absolute",
                    top: dragItem.y,
                    left: dragItem.x,
                  }}
                >
                  {dragItem.name.toUpperCase()}
                </div>
              </Draggable>
            ))}
        </div>
      ))}
      {button && <div></div>}
    </div>

    // </div>
  );
};

export default Sign;
