import PropTypes from "prop-types";
import styles from "./DirectorRejected.module.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import config from "../../../../../service/config";
import { useState } from "react";
import companyStore from "../../../CompanyStore";
const DirectorRejected = ({ onReject, isVisible, onclose, className = "" }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const kycid = companyStore.Dirct_KYC_id;
  const compid = companyStore.companyID;
  const [reason, setReason] = useState("");
  const handleReject = () => {
    if (typeof reason === "string" ? !reason.trim() : "") {
      alert("Reason for rejection is required!");
      return;
    }

    let obj = {
      kyc_id: kycid,
      status: "rejected",
      reason: reason,
    };
    axios
      .post(`${config.node_api_endpoint}/v1/director/kyc_request/update_status_demo_fe`, obj)
      .then(() => {
        console.log("Director Status Rejected");
        navigate(`/content/company/rejectedkyc`);
        onclose();
      })
      .catch((er) => {
        console.log(er);
        onclose();
      });
  };
  if (!isVisible) return null;
  return (
    <div className={`modal show d-block ${styles.rejectionModal}`} tabIndex="-1" role="dialog">
      <div className={`modal-dialog modal-dialog-centered ${styles.modalDialog}`} role="document">
        <div className={`modal-content ${styles.modalContent}`}>
          <div className={`modal-header ${styles.modalHeader}`}>
            <h5 className={`modal-title ${styles.modalTitle}`}>Please provide Reason for Rejection</h5>
          </div>
          <div className={`modal-body ${styles.modalBody}`}>
            <textarea
              className={`${styles.textarea}`}
              placeholder="Write reason for reject..."
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
          <div className={`modal-footer ${styles.modalFooter}`}>
            <div
              className={`${styles.cancelButton}  d-flex justify-content-center align-items-center`}
              onClick={onclose}
            >
              <button type="button" className={`btn`} style={{ color: "#2348AB" }}>
                Cancel
              </button>
            </div>
            <div
              className={`${styles.rejectButton}  d-flex justify-content-center align-items-center`}
              onClick={handleReject}
            >
              <button type="button" className={`btn`} style={{ color: "#ffffff" }}>
                Reject
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DirectorRejected.propTypes = {
  className: PropTypes.string,
};

export default DirectorRejected;
