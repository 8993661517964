import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import EsignMain from "./EsignMain";
import NewEsign from "./NewEsign/NewEsign";
import SignDoc from "./SignDoc/SignDoc";
import DocumentPreview from "./DocumentPreview/DocumentPreview";
import RequestesignGenerated from "./RequestEsignGenerated/RequestesignGenerated";
import { EsignWithEstamp } from "./EsignWithEstamp/EsignWithEstamp";

export default function EsignList() {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/content/esign") {
      navigate("esign/main");
    }
  }, []);
  return (
    <Routes>
      <Route path="esign/main" element={<EsignMain />} />
      <Route path="createesign" element={<NewEsign />} />
      <Route path="signaggr" element={<SignDoc />} />
      <Route path="preview/doc" element={<DocumentPreview />} />
      <Route path="esign-requested" element={<RequestesignGenerated />} />
      <Route path="esignwithestamp/:doc_id" element={<EsignWithEstamp />} />
    </Routes>
  );
}
