import { makeAutoObservable } from "mobx";

class CompanyStore {
  escrowId = "";
  escrowName = "";
  companyName = "";
  companyID = "";
  KYBId = "";
  directorId = "";
  doc_id = "";
  Dirct_KYC_id = "";
  Contract_id = "";
  AdminEmail = "";
  ContractName = "";
  DirectorExternalId = [];
  AcceptAll = false;

  constructor() {
    // MobX makes the store observable and reactive
    makeAutoObservable(this);
    this.hydrate(); // Retrieve stored data on initialization
  }

  // Actions to update the fields

  setContractName(name) {
    this.ContractName = name;
    localStorage.setItem("ContractName", name);
  }

  setEscrowName(name) {
    this.escrowName = name;
    localStorage.setItem("EscrowName", name);
  }

  setemail_id(name) {
    this.AdminEmail = name;
    localStorage.setItem("AdminEmail", name);
  }

  setEscrowId(id) {
    this.escrowId = id;
    localStorage.setItem("EscrowId", id);
  }

  setCompanyName(name) {
    this.companyName = name;
    localStorage.setItem("CompanyName", name);
  }

  setContractID(ID) {
    this.Contract_id = ID;
    localStorage.setItem("Contract_id", ID);
  }

  setCompanyID(id) {
    this.companyID = id;
    localStorage.setItem("CompanyID", id);
  }

  setKYBId(id) {
    this.KYBId = id;
    localStorage.setItem("KYBId", id);
  }
  setDirect_KYC_ID(id) {
    this.Dirct_KYC_id = id;
    localStorage.setItem("Dirct_KYC_id", id);
  }

  setDirectorId(id) {
    this.directorId = id;
    localStorage.setItem("DirectorId", id);
  }
  setDirectorExternalId(ids) {
    this.directorId = ids;
    localStorage.setItem("DirectorExternalId", ids);
  }

  setDoc_id(id) {
    this.doc_id = id;
    localStorage.setItem("Doc_id", id);
  }

  setAcceptAll(ids) {
    this.directorId = ids;
    localStorage.setItem("AcceptAll", ids);
  }

  // Hydrate the store from localStorage when the app is initialized
  hydrate() {
    const storedEscrowName = localStorage.getItem("EscrowName");
    const storedEscrowId = localStorage.getItem("EscrowId");

    const storedCompanyName = localStorage.getItem("CompanyName");
    const storedCompanyID = localStorage.getItem("CompanyID");
    const storedKYBId = localStorage.getItem("KYBId");
    const storedDirectorId = localStorage.getItem("DirectorId");
    const storedDocId = localStorage.getItem("Doc_id");
    const storedDirectKYC_Id = localStorage.getItem("Dirct_KYC_id");
    const storedContract_ID = localStorage.getItem("Contract_id");
    const storedEmail_id = localStorage.getItem("AdminEmail");
    const storedContractName = localStorage.getItem("ContractName");
    const storedDirectorExternalId = localStorage.getItem("DirectorExternalId");
    const storedAcceptAll = localStorage.getItem("AcceptAll");

    if (storedEscrowName) this.escrowName = storedEscrowName;
    if (storedEscrowId) this.escrowId = storedEscrowId;
    if (storedCompanyName) this.companyName = storedCompanyName;
    if (storedCompanyID) this.companyID = storedCompanyID;
    if (storedKYBId) this.KYBId = storedKYBId;
    if (storedDirectorId) this.directorId = storedDirectorId;
    if (storedDocId) this.doc_id = storedDocId;
    if (storedDirectKYC_Id) this.Dirct_KYC_id = storedDirectKYC_Id;
    if (storedContract_ID) this.Contract_id = storedContract_ID;
    if (storedEmail_id) this.AdminEmail = storedEmail_id;
    if (storedContractName) this.ContractName = storedContractName;
    if (storedDirectorExternalId) {
      this.DirectorExternalId = storedDirectorExternalId;
    }
    if (storedAcceptAll) this.AcceptAll = storedAcceptAll;
  }
}

const companyStore = new CompanyStore();
export default companyStore;
