import PropTypes from "prop-types";
import styles from "./ContractDetails.module.css";
import axios from "axios";
import config from "../../../../service/config";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RemoveModel from "./RemoveUser/RemoveModel";
import companyStore from "../../CompanyStore";

const ContractDetails = ({ className = "" }) => {
  const navigate = useNavigate();
  const [clicked, setClicked] = useState(false);
  const [DOCX, setpdfbase64] = useState({});
  const [Counterparty, setCounterparty] = useState([]);
  const [data, setData] = useState({});
  const [remove, setremove] = useState("");
  const [contrname, SetcontrName] = useState("");
  const [partyName, setPartyName] = useState("");

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: smooth scrolling
    });
  };

  useEffect(() => {
    let obj = {
      escrow_id: companyStore.escrowId,
      contract_id: companyStore.Contract_id,
    };

    axios
      .post(`${config.mock_api_endpoint}/api/getDocument`, obj)
      .then((res) => {
        // setCounterparty(res.data.counter_parties);
        // console.log("getDocument--->",res.data);

        // console.log("getDocument--->",res.data);

        setpdfbase64(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .post(`${config.mock_api_endpoint}/api/getContractData`, obj)
      .then((res) => {
        console.log("dattaaa-->", res.data);

        setData(res.data);
        setCounterparty(res.data.counter_parties);
        SetcontrName(data?.contract_name);
        companyStore.setContractName(res.data.contract_name);
      })

      .catch((err) => {
        console.log("%o", err);
      });
  }, []);

  const itemsPerPage = 5; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate total pages
  const totalPages = Math.ceil(Counterparty.length / itemsPerPage);

  // Get current items
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = Counterparty.slice(startIndex, startIndex + itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleNavi = () => {
    navigate("/content/contract/viewcontract");
  };

  const handleNaviEsign = () => {
    navigate("/content/esign/createesign");
  };
  const handleDownload = () => {
    const base64DOCX = DOCX;
    // console.log(base64DOCX);
    // console.log(base64DOCX);

    const link = document.createElement("a");
    link.href = `data:application/docx;base64,${base64DOCX}`;
    link.download = "download.docx";
    link.click();
  };

  return (
    <div className={[styles.pageContainer, className].join(" ")}>
      <div className={styles.frameParent}>
        <div
          className={styles.arrowNarrowLeftParent}
          onClick={() => {
            navigate("/content/contract/main");
          }}
        >
          <img className={styles.arrowNarrowLeftIcon} alt="" src="/arrownarrowleft.svg" />
          <div className={styles.back}>Back</div>
        </div>
        <div className={styles.frameWrapper}>
          <div className={styles.frameGroup}>
            <div className={styles.contractsDetailsWrapper}>
              <div className={styles.contractsDetails}>Contracts Details</div>
            </div>
            <div className={styles.frameContainer}>
              <div className={styles.groupParent}>
                <img className={styles.frameChild} alt="" src="/group-2369@2x.png" />
              </div>
              <div className={styles.frameDiv}>
                <div className={styles.frameParent1}>
                  <div className={styles.nameOfContractParent}>
                    <div className={styles.nameOfContractParent}>
                      <div className={styles.nameOfContract}>Name of Contract</div>
                      <div className={styles.div}>:</div>
                    </div>
                    <div className={styles.contractsDetails}>{data?.contract_name || "Loading.."}</div>
                  </div>
                  <div className={styles.nameOfContractParent}>
                    <div className={styles.nameOfContractParent}>
                      <div className={styles.nameOfContract}>Contract Admin's name</div>
                      <div className={styles.div}>:</div>
                    </div>
                    <div className={styles.back}>{data?.admin_name || "Loading..."}</div>
                  </div>
                  <div className={styles.nameOfContractParent}>
                    <div className={styles.nameOfContractParent}>
                      <div className={styles.statusWrapper}>
                        <div className={styles.contractsDetails}>Status</div>
                      </div>
                      <div className={styles.div}>:</div>
                    </div>
                    <div className={styles.back}>{data?.status || "Loading..."}</div>
                  </div>
                  <div className={styles.nameOfContractParent}>
                    <div className={styles.nameOfContractParent}>
                      <div className={styles.statusWrapper}>
                        <div className={styles.contractsDetails}>Created Date/Time</div>
                      </div>
                      <div className={styles.div}>:</div>
                    </div>
                    <div className={styles.aug2024}>{data?.created_at?.split("T")[0] || "Loading..."}</div>
                  </div>
                </div>
                <div
                  className={styles.viewDocumentWrapper}
                  onClick={() => {
                    handleNavi();
                  }}
                >
                  <b className={styles.viewDocument}>View Document</b>
                </div>
                {data.status == "Accepted" && (
                  <div
                    className={styles.viewDocumentWrapper1}
                    onClick={() => {
                      handleDownload();
                    }}
                  >
                    <b className={styles.viewDocument}>Download Document</b>
                  </div>
                )}
                {data.status == "Accepted" && (
                  <div
                    className={styles.viewDocumentWrapper1}
                    onClick={() => {
                      handleNaviEsign();
                    }}
                  >
                    <b className={styles.viewDocument}>Request E-sign</b>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.detailContainer}>
              <div className={styles.detailHeader}>Counterparty details</div>
              <table className={styles.table}>
                <thead className={styles.thead}>
                  <tr className={styles.tr}>
                    <th className={styles.th}>Name</th>
                    <th className={styles.th}>Company Name</th>
                    <th className={styles.th}>Email ID</th>
                    <th className={styles.th}>Role</th>
                    <th className={styles.th}>Status</th>
                    <th className={styles.th}>Action</th>
                  </tr>
                </thead>
                <tbody className={styles.tbody}>
                  {currentItems && currentItems.filter((data) => data.status !== "Removed").length > 0 ? (
                    currentItems
                      .filter((data) => data.status !== "Removed")
                      .map((detail, index) => (
                        <tr className={styles.tr} key={index}>
                          <td className={styles.td}>{detail.name}</td>
                          <td className={styles.td}>{detail.company_name}</td>
                          <td className={styles.td}>{detail.email}</td>
                          <td className={styles.td}>
                            {detail.email == data.current_edit_access ? "Editor" : "Viewer"}
                          </td>
                          <td className={styles.td}>{detail.status}</td>
                          <td
                            className={styles.button}
                            onClick={() => {
                              setClicked(true);
                              handleScrollToTop();
                              // handleRemove();
                              setremove(detail.email);
                              setPartyName(detail.name);
                            }}
                          >
                            Remove
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan="6" className={styles.noRecords}>
                        No Records Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className={styles.pagination}>
                {Array.from({ length: totalPages }, (_, index) => (
                  <button
                    key={index + 1}
                    className={`${styles.pageButton} ${currentPage === index + 1 ? styles.active : ""}`}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <RemoveModel
        isVisible={clicked}
        party={remove}
        partyName={partyName}
        Counterparty={Counterparty}
        contName={contrname}
        onclose={() => {
          setClicked(false);
        }}
      />
    </div>
  );
};

ContractDetails.propTypes = {
  className: PropTypes.string,
};

export default ContractDetails;
