import React, { Fragment, useCallback, useEffect, useState } from "react";
import Sign from "../Sign/Sign";
import axios from "axios";
import styles from "./SignDoc.module.css";
import { useNavigate, useParams } from "react-router-dom";
import RequestesignGenerated from "../RequestEsignGenerated/RequestesignGenerated";
import config from "../../../../service/config";
import companyStore from "../../CompanyStore";

export default function SignDoc() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [clicked, setclicked] = useState(null);
  const [Requested, setRequested] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    console.log("Doc ID", companyStore.doc_id);

    setId(companyStore.companyID);
    const doc_id = companyStore.doc_id;
    console.log("previewing documents");
    axios.post(`${config.node_api_endpoint}/v1/esign/getdocument/`, { doc_id }).then((res) => {
      console.log(res.data);
      setData(res.data.signing_parties);
    });
  }, []);
  const call = (element) => {
    console.log(element);
  };
  const handlenavi = () => {
    navigate(`/content/esign/createesign`);
  };

  const handleSwitchChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <Fragment>
      <div className={styles.maindiv}>
        <div
          className={styles.arrowNarrowLeftParent}
          onClick={() => {
            handlenavi();
          }}
        >
          <img className={styles.arrowNarrowLeftIcon} alt="" src="/arrownarrowleft.svg" />
          <div className={styles.back}>Back</div>
        </div>
        <div className={styles.containAll}>
          <div className={styles.scrollableContainer}>
            <Sign button={clicked} reason={isChecked ? "E-SignWithE-Stamp" : "E-Sign"} />
          </div>
          <div>
            <div className={styles.detailsContentWrapper}>
              <div className={styles.detailsContent}>
                <div className={styles.companyAddress}>
                  <h1 className={styles.escrowStackPrivate}>Signature</h1>
                </div>
                <div className={styles.rightinfo}>
                  {data.map((detail) => (
                    <div className={styles.detailsHeaders}>
                      <button className={styles.contain}>
                        <div className={styles.icon}>{detail.name.slice("")[0]}</div>

                        <div className={styles.detailParent}>
                          <b className={styles.detail}>
                            <div className={styles.detailsinfo}>{detail.name}</div>
                            <div className={styles.detailsinfo}>{detail.email}</div>
                            <div className={styles.detailsinfo}>Sign Type: Aadhar</div>
                          </b>
                        </div>
                      </button>
                    </div>
                  ))}
                  <div className="d-flex justify-content-between w-100 mt-2 mb-2">
                    <div>Add eStamping</div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input p-2"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                        checked={isChecked}
                        onChange={handleSwitchChange}
                      />
                      <label className="form-check-label" htmlFor="flexSwitchCheckDefault"></label>
                    </div>
                  </div>
                  <button
                    className={styles.continuebtn}
                    onClick={() => {
                      setclicked(true);
                      console.log("retriving data");
                      // navigate(`/content/esign-requested/${id}`)
                    }}
                  >
                    <b>Send</b>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
